<template>
    <v-row style="margin-top: 0px;background-color:white;">
        <v-col cols="12">
            <titulo-principal-white-cx titulo="La forma más fácil de encontrar tu próximo empleo" />
        </v-col>
        <v-col cols="12" class="pl-8 pr-8">
            <v-text-field
                solo
                v-model="puesto"
                @change="readDataFromAPI()"
                class="text-lg"
                label="¿Qué empleo buscas?"
                append-icon="mdi-briefcase-search"
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" class="pl-6 ">
            <span class="text-base font-bold text-center pl-1">FILTRAR POR:</span>
            <v-row> 
                <v-col cols="12" class="pl-4 pt-10">
                    <v-select
                        :items="items_fecha_publicacion"
                        @change="readDataFromAPI()"
                        label="Fecha de publicación"
                        v-model="fecha_publicacion"
                    ></v-select>
                    <v-select
                        :items="items_provincias"
                        @change="readDataFromAPI()"
                        label="Lugar de trabajo"
                        v-model="provincia"
                        
                    ></v-select>
                    <v-select
                        :items="items_areas"
                        @change="readDataFromAPI()"
                        label="Área"
                        v-model="area"
                        multiple
                        chips
                    ></v-select>
                    <v-select
                        :items="items_tipos_puesto"
                        @change="readDataFromAPI()"
                        label="Tipo de puesto"
                        v-model="tipo_puesto"
                        multiple
                        chips
                    ></v-select>

                    <v-select
                        :items="items_rangos_salariales"
                        @change="readDataFromAPI()"
                        label="Rango salarial"
                        v-model="rango_salarial"
                        
                    ></v-select>
                </v-col>
            </v-row>

        </v-col>
        <v-col cols="12" md="9" class="py-0">
            <v-data-table
                no-data-text="Postulaciones no disponibles"
                no-results-text="Resultados no encontrados"
                :page="page"
                hide-default-footer
                :pageCount="numberOfPages"
                :headers="headers"
                :items="AdministrarPublicaciones"
                :options.sync="options"
                :server-items-length="totalAdministrarPublicaciones"
                :loading="loading"
                :search="search"
                sort-by="nombre"
                class="elevation-1 cursor-pointer"
                @click:row="goEspecific"
            >
                <template v-slot:top>
                    <v-row>
                        <v-col cols="12">
                            <v-pagination
                                class="text-center"
                                v-model="page"
                                :length="numberOfPages"
                            ></v-pagination>
                        </v-col>
                    </v-row>
                </template>

                
                <template v-slot:[`item.puesto`]="{ item }">
                    <div class="p-4">
                        <span class="text-xl">{{item.puesto}} </span>
                        <br/>
                        <v-icon
                            small
                            :color="system.colores.icons_2"
                        >
                            mdi-map-marker 
                        </v-icon>
                        <span class="text-xs"><i>{{ item.ciudad }}, {{ item.provincia }}</i></span>
                        <br/>
                        <p>{{ item.descripcion }}</p>
                    </div>
                </template>
                <template v-slot:[`item.fecha_publicacion`]="{ item }">
                    <div class="p-4">
                        <v-chip color="primary" large class="ma-2">
                            <v-icon left>
                                mdi-account-clock
                            </v-icon>
                            {{ item.tipopuesto }}
                        </v-chip>
                        <span class="text-xs"><i>{{ processDate(item.fecha_publicacion) }}</i></span>
                        <br/>
                    </div>
                </template>
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12">
                            <v-pagination
                                class="text-center"
                                v-model="page"
                                :length="numberOfPages"
                            ></v-pagination>
                        </v-col>
                    </v-row>
                </template>
                
            </v-data-table>
            <br/>
        </v-col>
    </v-row>
</template>

<script>
import Api from '@/api';
import Cookies from 'js-cookie'
import TituloPrincipalWhiteCx from '@/components/titulos/TituloPrincipalWhiteCx'
import { mapGetters } from 'vuex'


export default {
    name: 'OfertasLaboralesParaLosUsuarios',
    components: { TituloPrincipalWhiteCx},
    data () {
        return {
            
            page: 1,
            totalAdministrarPublicaciones: 0,
            puesto: '',
            background: '',
            linkpdf: '',
            dialogLink: false,
            numberOfPages: 0,
            loading: true,
            uno: 1,
            dos: 2,
            tres: 3,
            cuatro: 4,
            search: '',
            nombreCalificar: '',
            options: {},
            AdministrarPublicaciones: [],
            dialogDelete: false,
            dialogEstado: false,
            dialogCalificacion: false,
            headers: [
                { text: ' ', value: 'puesto', sortable: false },
                { text: ' ', value: 'fecha_publicacion', sortable: false },
            ],
            offset: true,

            items_fecha_publicacion: [
                {value: null, text:'Cualquiera'},
                {value: 5, text:'Últimos 5 días'},
                {value: 10, text:'Últimos 10 días'},
                {value: 15, text:'Últimos 15 días'},
                {value: 31, text:'Último mes'},
            ],
            items_tipos_puesto: [],
            items_rangos_salariales: [],
            items_areas: [],
            items_provincias: [],
            items_actions: [{ text: 'Descargar CV'},{ text: 'Calificar'}],
            items_genero: [
                {value:null,text:'Cualquiera'},
                {value:'Femenino',text:'Femenino'},
                {value:'Masculino',text:'Masculino'},
                {value:'Otro',text:'Otro'}
            ],
            items_nivel_educativo: [
                {value: null, text:'Cualquiera'},
                {value:"Educación Inicial", text:'Educación Inicial'},
                {value:'Educación General Básica',text:'Educación General Básica'},
                {value:'Bachillerato',text:'Bachillerato'},
                {value:'Terciario',text:'Terciario'},
                {value:'Universitario',text:'Universitario'},
                {value:'Posgrado',text:'Posgrado'},
                {value:'Master',text:'Master'},
                {value:'Doctorado',text:'Doctorado'},
                {value:'Otro',text:'Otro'}
            ],
            items_presencia: [{value: null, text:'Cualquiera'},{value:1,text:"Mono"},{value:2,text:"Dragón"},{value:3,text:"Delfín"}],
            items_contactos: [{value: null, text:'Cualquiera'},{value:1,text:"Dorado"},{value:2,text:"Negro"},{value:3,text:"Check"}],
            items_verificado: [{value: null, text:'Cualquiera'},{value:1,text:"Trébol"},{value:2,text:"Pic"},{value:3,text:"Corazón"},{value:4,text:"Brillo"}],

            editedIndexCalificacion: -1,
            editedItemCalificacion: {
                _id: null,
                presencia: null,
                contactos: null,
                verificado: null
            },
            defaultItemCalificacion: {
                _id: null,
                presencia: null,
                contactos: null,
                verificado: null
            },


            fecha_publicacion: null,
            tipo_puesto: null,
            rango_salarial: null,
            area: null,
            provincia: null,

            genero: null,
            edad_desde: null,
            edad_hasta: null,
            salario_pretendido_hasta: null,
            experiencia_laboral_minima: null,
            nivel_educativo: null,
            valoracion_presencia: null,
            valoracion_contactos: null,
            valoracion_verificado: null
        }
    },
    computed: {
        ...mapGetters(['system','baseurl']),
        titulo() {
            return this.$route.params.id ? 'Postulantes para: '+this.puesto : 'Base de postulantes'
        },
        urlCalificacion() {
            return `/hojadevida/${this.editedItemCalificacion._id}`
        },
        methodCalificacion() {
            return 'put'
        },
        dataCalificacion() {
            return {
                presencia: this.editedItemCalificacion.presencia,
                contactos: this.editedItemCalificacion.contactos,
                verificado: this.editedItemCalificacion.verificado
            }
        }
    },
    watch: {

        
      options: {
        handler() {
            this.readDataFromAPI();
        },
      },  
      dialogDelete (val) {
        val || this.closeDelete()
      },
      dialogEstado (val) {
        val || this.closeEstado()
      },
      search: {
          handler() {
            this.readDataFromAPI();
        },
      }
    },
    created() {
        this.getTipoPuestos();
        this.getRangosSalariales();
        this.getAreas();
        this.getProvincias();
        this.readDataFromAPI();
        //this.$store.dispatch('setItemSelected','AdministrarPublicaciones_listar')
    },
    methods: {
        processDate (valor) {
            const date = new Date(valor)
            const year = date.getFullYear()
            let month = date.getMonth()+1
            let dt = date.getDate()
        
            if (dt < 10) {
            dt = '0' + dt
            }
            if (month < 10) {
            month = '0' + month
            }
        
            return dt+'-' + month + '-'+year
        },
        goEspecific(item) {
            this.$router.push({path:"/ofertas-laborales/"+item._id});
        },
        async success (response) {
            this.$notify(
                {
                    group: "bottom",
                    title: "Datos guardados",
                    text: response.data ? response.data.message : "Actualizado correctamente.",
                    type: 'success'
                },
                4000
            );
            this.dialogCalificacion = false;
            await this.readDataFromAPI()
        },
        error () {
            this.$notify(
                {
                    group: "bottom",
                    title: "Error",
                    text: "Ocurrió un inconveniente",
                    type: "error"
                },
                4000
            );
            
        },

        editItemCalificacion (item) {
            this.nombreCalificar = item.nombre;
            this.editedItemCalificacion = item.hoja_de_vida_info[0]
            this.dialogCalificacion = true
        },

        async descargarCV (item) {
            const HOJA_DE_VIDA_ID = item.hoja_de_vida_info ? item.hoja_de_vida_info[0]._id : null;
            const USUARIO_ID = item ? item.usuario_id : null;
            console.log(HOJA_DE_VIDA_ID);
            this.linkpdf= '';
            this.dialogLink = true;
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers,{})
                let response = await api.call_respuesta_directa(`/hojadevida/${HOJA_DE_VIDA_ID}/usuario/${USUARIO_ID}/pdf`) 
                this.dialogLink = true;
                this.linkpdf = response.data.data
            }
            
        },


        

        fetchEntriesDebounced() {
            // cancel pending call
            clearTimeout(this._timerId)
            this.page = 1
            // delay new call 500ms
            this._timerId = setTimeout(() => {
                this.readDataFromAPI()
            }, 500)
        },

        async getTipoPuestos() {
            let headers = { 'Content-Type': 'application/json' }
            const api = new Api(this.baseurl,'get',headers)
            let response = await api.call_respuesta_directa(`/tipopuesto/select/all`) 
            response = response.data ? response.data.data : this.items_tipos_puesto
            this.items_tipos_puesto = response
            //this.items_tipos_puesto.unshift({value: null, text: 'Cualquiera'});
        },

        async getAreas() {
            let headers = { 'Content-Type': 'application/json' }
            const api = new Api(this.baseurl,'get',headers)
            let response = await api.call_respuesta_directa(`/areas-puestos/subareas/select/all`) 
            response = response.data ? response.data.data : this.items_areas
            this.items_areas = response
        },

        async getProvincias() {
            let headers = { 'Content-Type': 'application/json' }
            const api = new Api(this.baseurl,'get',headers)
            let response = await api.call_respuesta_directa(`/provincias/select/all`) 
            response = response.data ? response.data.data : this.items_provincias
            this.items_provincias = response
            this.items_provincias.unshift({value: null, text: 'Cualquiera'})
        },

        async getRangosSalariales() {
            let headers = { 'Content-Type': 'application/json' }
            const api = new Api(this.baseurl,'get',headers)
            let response = await api.call_respuesta_directa(`/rangosalarial/select/all`) 
            response = response.data ? response.data.data : this.items_rangos_salariales
            this.items_rangos_salariales = response
            this.items_rangos_salariales.unshift({value: null, text: 'Cualquiera'})
        },

        async readDataFromAPI() {
            
            this.loading = true;
            const { page, itemsPerPage } = this.options;
            let pageNumber = page - 1;
            let params = {
                size: itemsPerPage,
                page: pageNumber,
                //oferta_laboral_id: OFERTA_LABORAL_ID,
                nombre: this.puesto ? this.puesto : null, 
                fecha_publicacion: this.fecha_publicacion ? parseInt(this.fecha_publicacion) : null, 
                provincia_id: this.provincia ? parseInt(this.provincia) : null, 
                area_id: this.area ? JSON.stringify(this.area) : null,
                tipo_puesto_id: this.tipo_puesto ? JSON.stringify(this.tipo_puesto) : null,
                salario_id: this.rango_salarial ? parseInt(this.rango_salarial) : null,
                
            };
            
            let AdministrarPublicaciones = []
            let headers = { 'Content-Type': 'application/json' }
            const api = new Api(this.baseurl,'get',headers,{},params)
            let response = await api.call_respuesta_directa(`/postulacion-trabajo/public`) 
            AdministrarPublicaciones = response.data ? response.data.data : [];
            this.loading = false;
            this.totalAdministrarPublicaciones = response.data.totalAdministrarPublicaciones;
            this.numberOfPages = response.data.totalPages;
            
            this.AdministrarPublicaciones = AdministrarPublicaciones
            
        },

        estadoItem (item) {
            this.editedIndex = this.AdministrarPublicaciones.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogEstado = true
        },

        estadoItemConfirm () {
            this.estadoApi()
        },

        async estadoApi() {
            let response = false;
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null;
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.$store.getters.baseurl,'put',headers,{estado: "Finalizado"})
                response = await api.call_respuesta_directa(`/postulacion-trabajo/${this.editedItem._id}`) 
            }
            if (response && response.status == 200) {
                //this.AdministrarPublicaciones.splice(this.editedIndex, 1)
                this.closeEstado()
                this.readDataFromAPI()
            }else{
                this.$notify(
                    {
                        group: "bottom",
                        title: "Error",
                        text: "No se pudo finalizar publicación",
                        type: "error"
                    },
                    4000
                );
                this.closeEstado()
            }

        },

        deleteItem (item) {
            this.editedIndex = this.AdministrarPublicaciones.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm () {
            this.deleteApi()
        },

        async deleteApi() {
            let response = false;
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null;
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.$store.getters.baseurl,'delete',headers)
                response = await api.call_respuesta_directa(`/postulacion-trabajo/postulantes/${this.editedItem._id}`) 
            }
            if (response && response.status == 200) {
                this.AdministrarPublicaciones.splice(this.editedIndex, 1)
                this.closeDelete()
                this.readDataFromAPI()
            }else{
                this.$notify(
                    {
                        group: "bottom",
                        title: "Error",
                        text: "No se pudo eliminar empresa",
                        type: "error"
                    },
                    4000
                );
                this.closeDelete()
            }

        },


        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        closeEstado () {
            this.dialogEstado = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        }
    }
}
</script>

<style scoped>
    tbody tr:nth-child(odd) {
        background-color:rgba(79, 125, 147, 10%);
    }
</style>