<template>
    <span>
        <titulo-principal-cx style="word-break: break-word !important;" titulo="Revisa los resultados de tu evaluación de desempeño" />
        <v-btn
            color="teal"
            class="ma-2 white--text text-align-right"
            @click="mostrarDialog = true">
            Firmar y aceptar resultados
            <v-icon
                right
                dark
            >
                mdi-pencil
            </v-icon>
        </v-btn>
        <v-dialog v-model="mostrarDialog" style="z-index: 9999" max-width="500px" transition="dialog-top-transition">
            <v-card>
                <v-toolbar :style="{background: system.colores.fondo_menu_principal}">Aceptar resultados</v-toolbar>
                <v-card-text>
                    <div class="pa-4">
                        <h3>¿Estás de acuerdo que los resultados mostrados en este reporte son correctos?</h3>
                    </div>
                </v-card-text>
                
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1"  @click="closeComplete">Aceptar y firmar</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <vue-pdf-app :pdf="data.url_pdf_generado" @open="openHandler"></vue-pdf-app>
    </span>
</template>>

<script>

import Api from '@/api';
import Cookies from 'js-cookie'
import TituloPrincipalCx from '@/components/titulos/TituloPrincipalCx'
import VuePdfApp from 'vue-pdf-app'
//import FormCx from '@/components/forms/FormCx'
//import SubmitCx from '@/components/forms/SubmitCx'

import { mapGetters } from 'vuex'

export default {
    name: 'ReporteRevisionUsuario',
    data() {
        return {
            data: {
                url_pdf_generado: 'http://localhost:8000/public/reportes/reporte_desempeno_especifico_614ca42e06bc7092755f976a.pdf',
                url_pdf_firmado: '',
                direccion: "",
                empresa: "",
                estado: "",
                evaluacion: "",
                tipo: "",
                usuario: ""
            },
            mostrarDialog: false
        }
    },
    computed: {
        ...mapGetters(['system','baseurl']),

        
        
    },
    components: {
        TituloPrincipalCx,
        VuePdfApp
    },
    async created() {
        await this.readReporte()   
    },
    methods: {
        async readReporte() {
            const ID_REPORTE = this.$route.params.id;
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            if (userLogged && ID_REPORTE) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers,{},{})
                let response = await api.call_respuesta_directa(`/reportes/pendientes/${ID_REPORTE}`) 
                response = response.data ? response.data.data : this.data;
                this.data.data = response
            }
        },
        openHandler(pdfApp) {
            window._pdfApp = pdfApp;
        },

        async closeComplete() {
            const ID_REPORTE = this.$route.params.id;
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            if (userLogged && ID_REPORTE) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'put',headers,{},{})
                let response = await api.call_respuesta_directa(`/reportes/pendientes/${ID_REPORTE}`) 
                response = response.data ? response.data.data : this.data;
                this.mostrarDialog = false
                this.data.data = response
                this.readReporte()
                this.$notify(
                    {
                        group: "bottom",
                        title: "Firmado",
                        text: "Firmado documento correctamente",
                        type: "success"
                    },
                    4000
                );
                this.$router.push("/ev/evaluaciones-lista");
            }
        }
    },
    watch: {
      
    },
}
</script>

<style scoped>
   
</style>