<template>
    <section id="hero" style="padding-top: 80px;">
        <ofertas-laborales />
    
    </section>
    
</template>

<script>


//import TituloPrincipalWhiteCx from '@/components/titulos/TituloPrincipalWhiteCx'
import OfertasLaborales  from '@/pages/postulaciones/OfertasLaborales';
import { mapGetters } from 'vuex'


export default {
    name: 'OfertaLaboralUsuario',
    components: { OfertasLaborales },
    data () {
        return {
            titulo: "La forma más facil de encontrar tu próximo empleo",
            color: "#FFF",
            
        }
    },
    computed: {
        ...mapGetters(['system','baseurl'])
    },
    watch: {
      
    },
    created() {
        //this.readDataFromAPI()
        //this.$store.dispatch('setItemSelected','AdministrarPublicaciones_listar')
    },
    methods: {

        
    }
}
</script>

<style scoped>
    tbody tr:nth-child(odd) {
        background-color:rgba(79, 125, 147, 10%);
    }
</style>