import { render, staticRenderFns } from "./HomeSection.vue?vue&type=template&id=d44bd89c&"
import script from "./HomeSection.vue?vue&type=script&lang=js&"
export * from "./HomeSection.vue?vue&type=script&lang=js&"
import style0 from "./HomeSection.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./HomeSection.vue?vue&type=style&index=1&lang=css&"
import style2 from "./HomeSection.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VContainer,VHover,VImg,VParallax,VRow})
