<template>
    <v-row>
        <v-col cols="12" class="py-0">
            <titulo-principal-cx :titulo="titulo" />
        </v-col>
        <v-col cols="12" class="py-0">
            <form-cx action="#" :method="method" :data="data" :url="url" @success="success" @error="error">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="data.oferta_laboral.puesto"
                                :rules="rules.oferta_laboral.puesto.rule"
                                :counter="rules.oferta_laboral.puesto.counter"
                                label="Título"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-textarea
                                solo
                                name="input-7-4"
                                label="Descripción"
                                :rules="rules.oferta_laboral.descripcion.rule"
                                v-model="data.oferta_laboral.descripcion"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                :items="rangos_salariales"
                                label="Rango Salario"
                                v-model="data.oferta_laboral.salario_id"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                :items="tipos_puestos"
                                label="Tipo puesto"
                                v-model="data.oferta_laboral.tipo_puesto_id"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                :items="areas"
                                label="Área"
                                v-model="areapadre_id"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                :items="subareas"
                                label="Subárea"
                                v-model="data.oferta_laboral.area_id"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                :items="provincias"
                                label="Provincia"
                                v-model="data.oferta_laboral.provincia_id"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                :items="localidades"
                                label="Localidad"
                                v-model="data.oferta_laboral.localidad_id"
                            ></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox
                                v-model="data.oferta_laboral.postulante_discapacidad"
                                label="Apto discapacitados"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        
                        <v-col cols="12">
                            <div class="border-solid border-gray-500  pb-2 ">
                                <br/>
                                <h3 class="text-xl" :style="{color: system.colores.texto_titulo_1}"> Requisitos del puesto </h3>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-select
                                :items="sexos"
                                label="Sexo"
                                v-model="data.requisitos_empleo.sexo"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-checkbox
                                v-model="data.requisitos_empleo.sexo_excluyente"
                                label="Excluir sexo"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field
                                v-model="data.requisitos_empleo.edad_desde"
                                type="number"
                                label="Edad desde"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                v-model="data.requisitos_empleo.edad_hasta"
                                type="number"
                                label="Edad hasta"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-checkbox
                                v-model="data.requisitos_empleo.edad_excluyente"
                                label="Excluir edad"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="data.requisitos_empleo.salario_desde"
                                label="Salario desde"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="data.requisitos_empleo.salario_hasta"
                                label="Salario hasta"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-checkbox
                                v-model="data.requisitos_empleo.solicitar_remuneracion_excluyente"
                                label="Excluir salario"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-checkbox
                                v-model="data.requisitos_empleo.solicitar_remuneracion"
                                label="Solicitar remuneración pretendida"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-text-field
                                v-model="data.requisitos_empleo.exp_minima"
                                label="Experiencia mínima (años)"
                                placeholder="Años"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-checkbox
                                v-model="data.requisitos_empleo.exp_minima_excluyente"
                                label="Excluir experiencia mínima"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-select
                                :items="niveles_educativos"
                                label="Nivel educativo"
                                v-model="data.requisitos_empleo.nivel_educativo"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select
                                :items="estados_niveles_educativos"
                                label="Estado nivel educativo"
                                v-model="data.requisitos_empleo.estado_nivel_educativo"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-checkbox
                                v-model="data.requisitos_empleo.nivel_educativo_excluyente"
                                label="Excluir nivel educativo"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-data-table 
                                :headers="headers_idiomas"
                                :items="data.idiomas" 
                                no-data-text="Ningún idioma"
                                no-results-text="Resultados no encontrados"
                                
                                :loading="loadingIdiomas"
                                :search="searchIdiomas"
                                class="elevation-1">


                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-dialog v-model="dialogIdiomas" >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                                Agregar idioma
                                                </v-btn>
                                            </template>
                                            
                                            <v-card>
                                                <v-card-title>
                                                <span class="text-h5">{{ formTitleIdiomas }}</span>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="12" md="6">
                                                                <v-select
                                                                    :items="items_idiomas"
                                                                    label="Idioma"
                                                                    v-model="editedItemIdioma.idioma"
                                                                ></v-select>
                                                            </v-col>
                                                            <v-col cols="12" md="6">
                                                                <v-select
                                                                    :items="items_nivel_idiomas"
                                                                    label="Nivel"
                                                                    v-model="editedItemIdioma.nivel"
                                                                ></v-select>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>

                                                <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="closeIdiomas">
                                                    Cancelar
                                                </v-btn>
                                                <v-btn color="blue darken-1" text @click="saveIdioma">
                                                    Guardar
                                                </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                            
                                        </v-dialog>
                                        <v-dialog v-model="dialogDeleteIdiomas" max-width="500px">
                                            <v-card>
                                                <v-card-title class="text-h5">¿Estás seguro de eliminar?</v-card-title>
                                                <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="closeDeleteIdiomas">Cancel</v-btn>
                                                <v-btn color="blue darken-1" text @click="deleteItemConfirmIdiomas">OK</v-btn>
                                                <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="editItemIdiomas(item)"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon
                                        small
                                        @click="deleteItemIdiomas(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                    </template>
                            </v-data-table>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-checkbox
                                v-model="data.requisitos_empleo.idiomas_excluyente"
                                label="Excluir idiomas"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-btn color="blue darken-1" type="submit" block>
                                <v-icon left>mdi-content-save</v-icon> PUBLICAR
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </form-cx>
        </v-col>
    </v-row>
</template>>

<script>

import Api from '@/api';
import Cookies from 'js-cookie'
import TituloPrincipalCx from '@/components/titulos/TituloPrincipalCx'
import FormCx from '@/components/forms/FormCx'
//import SubmitCx from '@/components/forms/SubmitCx'

import { mapGetters } from 'vuex'


export default {
    name: 'PostularTrabajo',
    data () {
        return {
            rangos_salariales:[],
            tipos_puestos:[],
            areas:[],
            subareas:[],
            provincias: [],
            localidades:[],
            areapadre_id: null,
            sexos: ['Indistinto','Masculino','Femenino','Otro'],
            niveles_educativos: ['Educación Inicial','Educación General Básica','Bachillerato','Terciario','Universitario','Posgrado','Master','Doctorado','Otro'],
            estados_niveles_educativos: ['En curso','Incompleto','Finalizado'],
            data: {
                
                oferta_laboral:{
                    _id: null,
                    puesto: '',
                    descripcion: '',
                    salario_id: '',
                    tipo_puesto_id: '',
                    area_id: '',
                    provincia_id: '',
                    localidad_id: '',
                    postulante_discapacidad: 0,
                    estado: 'Publicado'
                },
                requisitos_empleo:{
                    _id: null,
                    empleo_id: null,
                    sexo: '',
                    sexo_excluyente: 0,
                    edad_desde: '',
                    edad_hasta: '',
                    edad_excluyente: 0,
                    salario_desde: '',
                    salario_hasta: '',
                    solicitar_remuneracion: 1,
                    solicitar_remuneracion_excluyente: 0,
                    exp_minima: 0,
                    exp_minima_excluyente: 0,
                    nivel_educativo: '',
                    estado_nivel_educativo: '',
                    nivel_educativo_excluyente: 0,
                    idiomas_excluyente: 0
                },
                idiomas: []


            },
            editedIndexIdioma: -1,
            editedItemIdioma: {
                _id: null,
                requisito_id: '',
                idioma: '',
                nivel: '',
                excluyente: 0,
                delete: false
            },
            defaultItemIdioma: {
                _id: null,
                requisito_id: '',
                idioma: '',
                nivel: '',
                excluyente: 0,
                delete: false
            },
            headers_idiomas: [
                { text: 'Idioma',align: 'start',sortable: false,value: 'idioma' },
                { text: 'Nivel',align: 'start',sortable: false,value: 'nivel' },
                { text: 'Acciones', value: 'actions', sortable: false }
            ],
            dialogIdiomas: false,
            dialogDeleteIdiomas: false,
            pageIdiomas: 1,
            totalIdiomas: 0,
            numberOfPagesIdiomas: 0,
            loadingIdiomas: false,
            searchIdiomas: '',
            optionsidiomas: {},
            items_idiomas: ['Alemán','Chino Mandarín','Coreano','Español','Francés','Holandés','Inglés','Italiano','Japonés','Portugués'],
            items_nivel_idiomas: ['Básico','Intermedio','Avanzado','Nativo'],
            rules: {
                oferta_laboral: {
                    puesto: {
                        rule: [
                            v => !!v || 'Título o puesto es requerido',
                            v => v.length <= this.rules.oferta_laboral.puesto.counter || `El título debe ser igual a ${this.rules.oferta_laboral.puesto.counter} caracteres`,
                        ],
                        counter: 50
                    },
                    descripcion: {
                        rule: [
                            v => !!v || 'Descripción es requerida',
                            v => v.length <= this.rules.oferta_laboral.descripcion.counter || `La descripción debe ser igual a ${this.rules.oferta_laboral.descripcion.counter} caracteres`,
                        ],
                        counter: 300
                    },
                    _id_empresa: {
                        rule: [
                            v => !!v || 'Empresa es requerida',
                        ]
                    }
                }
            },
            empresas: []
        }
    },
    computed: {
        ...mapGetters(['system','baseurl']),
        method () {
            return 'post'
        },
        formTitleIdiomas () {
            return this.editedIndexIdioma === -1 ? 'Agregar Idioma' : 'Editar Idioma'
        },
        
        readonly () {
            let readonly = false
            if (this.$route.params.id){
                if (this.$route.params.action == 'ver')
                    readonly = true
            }
            return readonly
        },
        url () {
            return '/postulacion-trabajo'
        },
        titulo () {
            let titulo = "Postular un trabajo"
            
            return titulo
        }
    },
    watch: {
        optionsidiomas: {
            handler() {
                //this.readIdiomasFromAPI();
            },
        }, 
        dialogIdiomas (val) {
            val || this.closeIdiomas()
        },
        dialogDeleteIdiomas (val) {
            val || this.closeDeleteIdiomas()
        },

        areapadre_id: async function(val) {
            //this.data.datoscontacto.localidad_id = '';
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            if (userLogged && this.areapadre_id ) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers)
                let response = await api.call_respuesta_directa(`/areas-puestos/select/all?padre_id=${val}`) 
                response = response.data ? response.data.data : this.subareas
                this.subareas = response
            }
        },

        'data.oferta_laboral.provincia_id': async function(val) {
            //this.data.datoscontacto.localidad_id = '';
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            if (userLogged && this.data.oferta_laboral.provincia_id ) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers)
                let response = await api.call_respuesta_directa(`/localidades/provincia/${val}/select/all`) 
                response = response.data ? response.data.data : this.localidades
                this.localidades = response
            }
        }
    },
    components: {
        TituloPrincipalCx,
        FormCx,
        //SubmitCx
    },
    async created() {
        let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
        let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
        const api = new Api(this.baseurl,'get',headers)
        let response = await api.call_respuesta_directa(`/rangosalarial/select/all`) 
        response = response.data ? response.data.data : this.rangos_salariales
        this.rangos_salariales = response

        response = await api.call_respuesta_directa(`/tipopuesto/select/all`) 
        response = response.data ? response.data.data : this.tipos_puestos
        this.tipos_puestos = response

        response = await api.call_respuesta_directa(`/areas-puestos/select/all`) 
        response = response.data ? response.data.data : this.areas
        this.areas = response

        response = await api.call_respuesta_directa(`/provincias/select/all`) 
        response = response.data ? response.data.data : this.provincias
        this.provincias = response
    },
    methods: {
        
        
        saveIdioma () {
            if (this.editedIndexIdioma > -1) {
            Object.assign(this.data.idiomas[this.editedIndexIdioma], this.editedItemIdioma)
            } else {
            this.data.idiomas.push(this.editedItemIdioma)
            }
            this.closeIdiomas()
        },
        

        closeIdiomas () {
            this.dialogIdiomas = false
            this.$nextTick(() => {
            this.editedItemIdioma = Object.assign({}, this.defaultItemIdioma)
            this.editedIndexIdioma = -1
            })
        },

        closeDeleteIdiomas () {
            this.dialogDeleteIdiomas = false
            this.$nextTick(() => {
            this.editedItemIdioma = Object.assign({}, this.defaultItemIdioma)
            this.editedIndexIdioma = -1
            })
        },

       

        editItemIdiomas (item) {
            this.editedIndexIdioma = this.data.idiomas.indexOf(item)
            this.editedItemIdioma = Object.assign({}, item)
            this.dialogIdiomas = true
        },

        deleteItemIdiomas (item) {
            this.editedIndexIdioma = this.data.idiomas.indexOf(item)
            this.editedItemIdioma = Object.assign({}, item)
            this.dialogDeleteIdiomas = true
        },

        deleteItemConfirmIdiomas () {
            this.deleteApiIdiomas()
            /*this.data.experiencias_laborales.splice(this.editedIndexExperiencias, 1)
            this.closeDeleteExperiencias()*/
        },

        async deleteApiIdiomas() {
            
            this.data.idiomas.splice(this.editedIndexIdioma, 1)
            this.closeDeleteIdiomas()
            
            

        },




        success (response) {
            this.$notify(
                {
                    group: "bottom",
                    title: "Datos guardados",
                    text: response.data ? response.data.message : "Empleo postulado correctamente.",
                    type: 'success'
                },
                4000
            );
            this.$router.push("/backoffice/administrar-publicaciones");
        },
        error () {
            this.$notify(
                {
                    group: "bottom",
                    title: "Error",
                    text: "Ocurrió un inconveniente",
                    type: "error"
                },
                4000
            );
            
        }
    }
}
</script>

<style scoped>
   
</style>