<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      dark
      style="background-color:#24324a;"
    >
      <v-list>
        <v-list-item>
          
          <v-list-item-content>
            <img src="@/assets/img/logo.png" alt="Logo"  width="60px"/>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item
          v-for="([icon, text, link], i) in items"
          :key="i"
          link
          :to="link"
          
        >
          <v-list-item-icon class="justify-center">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">{{
              text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      :color="color"
      :flat="flat"
      dark
      class="px-15"
      :class="{ expand: flat }"
    >
      <v-toolbar-title>
          <a href="https://externaecuador.com/">
            <v-img src="@/assets/img/logo.png" max-width="185px"/>
          </a>
      </v-toolbar-title>
      <v-spacer />
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-4"
        v-if="isXs"
      />
      <div v-else>
        
        
        <v-btn text link to="/ofertas-laborales">
          <span class="mr-2">OFERTAS LABORALES</span>
        </v-btn>
        
        <v-btn rounded outlined text link to="/ev">
          <span class="mr-2">EVALUACIONES</span>
        </v-btn>
        <v-btn rounded outlined text link to="/hv/hojadevida">
          <span class="mr-2">REGISTRAR CV</span>
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}

.v-application .secondary {
    background-color: #24324a !important;
    border-color: #24324a !important;
}
</style>

<script>

import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'

export default {
  data: () => ({
    drawer: null,
    isXs: false,
    items: [
      ["mdi-briefcase-account", "Ofertas laborales", "/ofertas-laborales"],
      ["mdi-chart-bar", "Evaluaciones", "/ev"],
      ["mdi-account-box", "Registrar CV", "/hv/hojadevida"]
    ],
  }),
  props: {
    color: String,
    flat: Boolean,
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 1245;
    },
    logout () {
      Cookies.remove("userLogged")
      this.$router.push("/auth");
    }
  },
  computed: {
    ...mapGetters(['system']),
    nombreSesion() {
      let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
      if (userLogged)
        return `${userLogged.user.nombre} ${userLogged.user.apellido}`;
      else
        return null;
    }
  },
  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
