var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.mostrarSkeleton)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('i',[_vm._v(_vm._s(this.test))]),_c('titulo-principal-cx',{staticStyle:{"word-break":"break-word !important"},attrs:{"titulo":_vm.titulo}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('h2',{staticClass:"display-1",staticStyle:{"word-break":"break-word !important"},style:({color: _vm.system.colores.texto_titulo_1})},[_vm._v(_vm._s(_vm.data.test.nombre))]),_c('v-spacer'),_c('span',{staticClass:"title",style:({color: _vm.system.colores.texto_titulo_1})},[_vm._v(" "+_vm._s(_vm.data.respondidas + '/' + _vm.data.total)+" ")])],1),_c('v-card-text',{staticStyle:{"word-break":"break-word !important"}},[_vm._v(" "+_vm._s(_vm.data.test.descripcion)+" ")]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.preguntas_kpis.length > 0),expression:"preguntas_kpis.length > 0"}],staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.preguntas_kpis,"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("KPIS")])],1)]},proxy:true},{key:"item.respuesta.value",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.respuesta.value,"large":"","persistent":"","save-text":"Guardar","cancel-text":"Cerrar"},on:{"update:returnValue":function($event){return _vm.$set(item.respuesta, "value", $event)},"update:return-value":function($event){return _vm.$set(item.respuesta, "value", $event)},"save":function($event){return _vm.save_kpis(item.respuesta)},"cancel":_vm.cancel_kpis,"open":_vm.open_kpis,"close":_vm.close_kpis},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 title"},[_vm._v(" Ingrese calificación ")]),_c('v-text-field',{attrs:{"label":"Calificación","single-line":"","counter":"","autofocus":""},model:{value:(item.respuesta.value),callback:function ($$v) {_vm.$set(item.respuesta, "value", $$v)},expression:"item.respuesta.value"}})]},proxy:true}],null,true)},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.respuesta.value !== null ? '' : 'yellow',"label":"","text-color":"black"}},[_vm._v(" "+_vm._s(item.respuesta.value !== null ? item.respuesta.value : 'Click para calificar')+" ")])],1)]}}],null,true)}),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor_kpis},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack_kpis = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snack_kpis),callback:function ($$v) {_vm.snack_kpis=$$v},expression:"snack_kpis"}},[_vm._v(" "+_vm._s(_vm.snackText_kpis)+" ")]),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.preguntas_desempeno.length > 0),expression:"preguntas_desempeno.length > 0"}],attrs:{"headers":_vm.headers,"items":_vm.preguntas_desempeno,"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("DESEMPEÑO")])],1)]},proxy:true},{key:"item.respuesta.value",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.respuesta.value,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item.respuesta, "value", $event)},"update:return-value":function($event){return _vm.$set(item.respuesta, "value", $event)},"save":function($event){return _vm.save_desempeno(item.respuesta)},"cancel":_vm.cancel_desempeno,"open":_vm.open_desempeno,"close":_vm.close_desempeno},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 title"},[_vm._v(" Ingrese calificación ")]),_c('v-text-field',{attrs:{"label":"Calificación","single-line":"","counter":"","autofocus":""},model:{value:(item.respuesta.value),callback:function ($$v) {_vm.$set(item.respuesta, "value", $$v)},expression:"item.respuesta.value"}})]},proxy:true}],null,true)},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.respuesta.value !== null ? '' : 'yellow',"label":"","text-color":"black"}},[_vm._v(" "+_vm._s(item.respuesta.value !== null ? item.respuesta.value : 'Click para calificar')+" ")])],1)]}}],null,true)}),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor_desempeno},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack_desempeno = false}}},'v-btn',attrs,false),[_vm._v("Cerrar")])]}}]),model:{value:(_vm.snack_desempeno),callback:function ($$v) {_vm.snack_desempeno=$$v},expression:"snack_desempeno"}},[_vm._v(" "+_vm._s(_vm.snackText_desempeno)+" ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }