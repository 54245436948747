<template>
    <v-row>
        <v-dialog v-model="dialogProcesando" persistent width="500">
            <v-card color="primary" dark>
                <v-card-text>
                    <br/> 
                    {{dialogMensaje}}
                    <br/><br/>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-col cols="12" class="py-0">
            <titulo-principal-cx :titulo="titulo" />
        </v-col>
        <v-col cols="12" class="py-0">

            <v-stepper v-model="step">
                <v-stepper-header>
                    <v-stepper-step :complete="step > 1" step="1">
                        Generar Datos
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="step > 2" step="2">
                        Generar PDF
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                <v-stepper-content step="1">
                    <form-cx action="#" :method="method" :data="dataParaPdf" @requeststarted="requeststarted" :url="urlPdf" @success="success" @error="error">
                        <v-row>
                            <v-col cols="12" lg="12">
                                <v-select
                                :items="empresas"
                                :rules="rules.empresas.rule"
                                v-model="id_empresa"
                                @change="setEmpresa"
                                label="Empresa"
                                required
                                ></v-select>
                            </v-col>
                            
                            <v-col cols="12" lg="6">
                                <v-select
                                :items="tests"
                                :rules="rules.tests.rule"
                                v-model="id_test"
                                label="Evaluación 1 a comparar"
                                required
                                ></v-select>
                            </v-col>

                            <v-col cols="12" lg="6">
                                <v-select
                                :items="testsComparar"
                                :rules="rules.tests.rule"
                                v-model="id_test_comparar"
                                label="Evaluación 2 a comparar"
                                required
                                ></v-select>
                            </v-col>

                            <v-col cols="12" lg="6">
                                <v-select
                                    :items="itemsUsuarios"
                                    v-model="id_usuario"
                                    label="Usuario a comparar"
                                    ></v-select>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <v-select
                                    :items="itemsFormatos"
                                    v-model="formato"
                                    label="Formato"
                                    ></v-select>
                            </v-col>
                            
                        </v-row>
                        
                        <v-row>
                            <v-col cols="12">
                                <submit-cx :disabled="!id_empresa || !id_test || !id_test_comparar || !id_usuario">Generar</submit-cx>
                            </v-col>
                        </v-row>
                    </form-cx>
                </v-stepper-content>
                <v-stepper-content step="2">
                    <v-row>
                        <v-col cols="4" v-show="linkpdf">
                            <br/>
                            <a :href="linkpdf" target="_blank">Ver Archivo >> </a>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-col>
    </v-row>
</template>

<script>

import Api from '@/api';
import Cookies from 'js-cookie'
import TituloPrincipalCx from '@/components/titulos/TituloPrincipalCx'
import FormCx from '@/components/forms/FormCx'
import SubmitCx from '@/components/forms/SubmitCx'

import { mapGetters } from 'vuex'

export default {
    name: 'ReporteDesempenoComparativo',
    props: {
        paso: {
            type: Number,
            default: 1
        },
        dataOut: {
            type: Object,
            default: null
        },
        dataIn: {
            type: Object,
            default: null
        },
        id_reporte_pendiente: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            id_test: null,
            id_test_comparar: null,
            id_usuario: null,
            formato: 'pdf',
            itemsFormatos:[
                {text: "PDF", value:"pdf"},
                {text: "EXCEL", value:"excel"}
            ],
            linkpdf: null,
            step: 1,
            tests: [],
            testsComparar: [],
            itemsUsuarios: [],
            dialogProcesando: false,
            semaforo_cualitativas: {
                excelente: {
                    color: '#35bcc1',
                    desde: 90.01,
                    hasta: 100.00,
                    label: "Excelente"
                },
                bueno: {
                    color: '#bbd263',
                    desde: 80.01,
                    hasta: 90.00,
                    label: "Bueno"
                },
                regular: {
                    color: '#fdd837',
                    desde: 60.01,
                    hasta: 80.00,
                    label: "Regular"
                },
                deficiente: {
                    color: '#e25f53',
                    desde: 0,
                    hasta: 60.00,
                    label: "Deficiente"
                }
            },
            semaforo_cuantitativas: {
                optimo: {
                    color: '#35bcc1',
                    desde: 100.01,
                    hasta: 120.00,
                    label: "Óptimo"
                },
                bueno: {
                    color: '#bbd263',
                    desde: 85.01,
                    hasta: 100.00,
                    label: "Bueno"
                },
                regular: {
                    color: '#fdd837',
                    desde: 70.01,
                    hasta: 85.00,
                    label: "Regular"
                },
                deficiente: {
                    color: '#e25f53',
                    desde: 0,
                    hasta: 70.00,
                    label: "Deficiente"
                }
            },
            rules: {
                tests: {
                    rule: [
                        v => !!v || 'Evaluación es requerida',
                    ]
                },
                empresas: {
                    rule: [
                        v => !!v || 'Empresa es requerida',
                    ]
                }
            },
            empresas:[],
            id_empresa: null,
            empresa: null
        }
    },
    computed: {
        ...mapGetters(['system','baseurl']),
        method () {
            return 'post'
        },
        url () {
            return '/reportes/desempeno/especifico/graficos'
        },
        urlPdf () {
            return '/reportes/desempeno/especifico/pdf/comparar'
        },
        titulo () {
            return "Reporte de Desempeño Comparativo"
        },
        dialogMensaje () {
            let mensaje = "Generando PDF. Puede tardar unos minutos, espere por favor ..."
            return mensaje
        },
        dataParaPdf () {
            return {
                _id_test: this.id_test,
                _id_test_comparar: this.id_test_comparar,
                _id_usuario: this.id_usuario,
                formato: this.formato
            }
        },
        data () {
            return {
                _id_test: this.id_test,
                _id_usuario: this.id_usuario,
                semaforo_cualitativas: this.semaforo_cualitativas,
                semaforo_cuantitativas: this.semaforo_cuantitativas
            }
        }
        
    },
    watch : {
        id_empresa () {
            this.getAllEvaluacionDesempenoByEmpresa()
            this.getAllUsuariosByEmpresa()
        },
        
    },
    components: {
        TituloPrincipalCx,
        FormCx,
        SubmitCx
    },
    async created() {

       /* if (this.dataIn !== null) {
            this.id_test = this.dataIn._id_test
            this.id_usuario = this.dataIn._id_usuario
        }
        if (this.dataOut !== null) {
            //this.dialogProcesando = false
            this.step = 2
        }
    */


        let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
        let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
        
        /*let api = new Api(this.baseurl,'get',headers,{},{tipo:'desempeno'})
        let response = await api.call_respuesta_directa(`/tests/select/all`) 
        response = response.data ? response.data.data : this.tests
        this.tests = response*/


        
        let api = new Api(this.baseurl,'get',headers)
        let response = await api.call_respuesta_directa(`/empresas/select/all`) 
        response = response.data ? response.data.data : this.empresas
        this.empresas = response
    },
    methods: {
        
        setEmpresa(value) {
            console.dir(value)
        },

        async getAllUsuariosByEmpresa() {
            this.loading = true;
            let params = {
                _id_empresa: this.id_empresa
            }
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            let usuarios = []
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers,{},params)
                let response = await api.call_respuesta_directa('/usuarios/select/all') 
                usuarios = response.data ? response.data.data : [];
                this.loading = false;
            }
            this.itemsUsuarios = usuarios
        },

        async getAllEvaluacionDesempenoByEmpresa() {
            this.loading = true;
            let params = {
                _id_empresa: this.id_empresa,
                tipo:"desempeno"
            }
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            let usuarios = []
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers,{},params)
                let response = await api.call_respuesta_directa('/tests/select/all/custom') 
                usuarios = response.data ? response.data.data : [];
                this.loading = false;
            }
            this.tests = usuarios
            this.testsComparar = usuarios
            //this.$emit('onUsuarios',usuarios)
        },

        async getAllTestsByUsuario() {
            this.loading = true;
            let params = {
                "dataIn._id_usuario": this.id_usuario,
                "empresa": this.empresa
            }
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            let usuarios = []
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers,{},params)
                let response = await api.call_respuesta_directa('/usuarios/select/all') 
                usuarios = response.data ? response.data.data : [];
                this.loading = false;
            }
            this.itemsUsuarios = usuarios
            //this.$emit('onUsuarios',usuarios)
        },

        requeststarted () {
            this.dialogProcesando = true
        },

        
        error () {
            this.dialogProcesando = false
            this.$notify(
                {
                    group: "bottom",
                    title: "Error",
                    text: "Ocurrió un inconveniente",
                    type: "error"
                },
                4000
            );
            
        },

        success (response) {
            
            this.$notify(
                {
                    group: "bottom",
                    title: "Datos generados",
                    text: "PDF Generado correctamente.",
                    type: 'success'
                },
                4000
            );

            this.dialogProcesando = false
            this.linkpdf = response.data.data
            this.step = 2

            //this.$router.push("/backoffice/reportes/pendientes");
            
        },

        successPdf (response) {
            this.dialogProcesando = false
            this.$notify(
                {
                    group: "bottom",
                    title: "Datos generados",
                    text: "PDF Generado correctamente.",
                    type: 'success'
                },
                4000
            );

            this.linkpdf = response.data.data
            
        }
    }
}
</script>

<style scoped>
   
</style>