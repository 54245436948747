<template>
  <section id="hero">
    <v-parallax dark src="@/assets/img/bgHero.jpg" height="750" id="parallax">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="12" xl="8">
              <h1 class="display-1 mb-4">SU RECURSO MÁS VALIOSO EN LAS MEJORES MANOS</h1>
              <h1 class="display-2 font-weight-light">
                <span class="font-weight-bold">EXTERNA</span> TALENT HUNTERS
              </h1>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="hidden-sm-and-down"> </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="svg-border-waves text-white">
        <v-img src="@/assets/img/borderWaves.svg" />
      </div>
    </v-parallax>
    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-row align="center" justify="space-around">
             <v-col cols="12" class="text-center">
              <h3 class="font-weight-bold display-1" style="color: #FFF;">¿QUÉ NOS HACE ÚNICOS?</h3>
            </v-col> 
            <v-col
              cols="12"
              sm="5"
              md="4"
              lg="2"
              class="text-center"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-bold">{{ feature.title }}</h1>
                  <h4 class="font-weight-regular subtitle-1 text-justify">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="svg-border-waves">
      <img src="~@/assets/img/wave2.svg" />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      videoId: "i8IvvHJssWE",
      features: [
        {
          img: require("@/assets/img/icon2.png"),
          title: "Personalización",
          text: "El objetivo de nuestra firma es explorar las necesidades reales de nuestros clientes, permitiéndonos ajustar nuestra gestión para brindar soluciones que impacten en las empresas a largo plazo.",
        },
        {
          img: require("@/assets/img/icon1.png"),
          title: "Tiempos de respuesta",
          text: "Nuestro equipo, procesos y tecnología nos permiten tener los tiempos de respuesta más competitivos en el mercado.",
        },
        {
          img: require("@/assets/img/icon3.png"),
          title: "Nuestro equipo",
          text: "Nuestra firma cuenta con un Equipo de Expertos en diferentes áreas de: la Gestión de Talento, Economía empresarial, Derecho, Contabilidad, Psicología, entre otras, lo que nos permite asesorar de manera integral a su empresa.",
        },
        {
          img: require("@/assets/img/icon4.png"),
          title: "Servicio garantizado",
          text: "Tenemos la seguridad de los resultados de nuestros servicios, por esto tenemos garantías en todos nuestros procesos.",
        },
        {
          img: require("@/assets/img/icon5.png"),
          title: "Satisfacción del cliente",
          text: "El núcleo de nuestra empresa son nuestros clientes, por esto, buscamos su satisfacción total logrando vínculos duraderos con todos ellos.",
        },
      ],
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },
  methods: {
    ready(event) {
      this.player = event.target;
    },
    
    change() {
      // when you change the value, the player will also change.
      // If you would like to change `playerVars`, please change it before you change `videoId`.
      // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
      // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
      this.videoId = "another video id";
    },
    stop() {
      this.player.stopVideo();
    },
    pause() {
      this.player.pauseVideo();
    },
  },
};
</script>

<style lang="scss">

.v-parallax {
  padding-top: 70px !important;
}

.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;

  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>

<style>

img, video {
  max-width: none !important;
  height: auto !important;
}

.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
</style>

<style>
section {
  position: relative;
}
</style>
