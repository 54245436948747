<template>
    <v-row>
        <v-dialog v-model="loading" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Confirmando, por favor espere ...
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogProcesado" persistent width="500" class="p-4">
            <v-card color="green" dark class="p-4">
                <v-card-text class="p-4">
                    <br>
                    <p class="text-2xl p-2">Usuario confirmado correctamente.</p>
                    <br>
                    <v-btn link to="/auth">Ir a login</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import Api from '@/api';
import { mapGetters } from 'vuex'


export default {
    name: 'ConfirmUsuario',
    components: {  },
    data () {
        return {
            dialogProcesado: false,
            loading: true
        }
    },
    computed: {
        ...mapGetters(['system','baseurl'])
    },
    watch: {
      
    },
    created() {
        this.readToken()
    },
    methods: {

        async readToken() {
            this.loading = true;
            const TOKEN = this.$route.params.token;
            let params = {
                token: TOKEN
            }
            let headers = { 'Content-Type': 'application/json' }
            const api = new Api(this.baseurl,'get',headers,{},params)
            let response = await api.call_respuesta_directa('/auth/confirm') 
            if (response.status === 200) {
                this.dialogProcesado = true;
            } else {
                this.$notify(
                    {
                        group: "bottom",
                        title: "Error",
                        text: response.data.message ? response.data.message : "Datos incorrectos.",
                        type: "error"
                    },
                    6000
                );
            }
            this.loading = false;

            
        },

        
    }
}
</script>

<style scoped>
    tbody tr:nth-child(odd) {
        background-color:rgba(79, 125, 147, 10%);
    }
</style>