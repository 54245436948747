<template>
    <v-row style="margin-top: 84px;background-color:white;">
        <v-col cols="12" class="pl-8">
            <router-link :to="{ name: 'OfertaLaboralUsuario'}">
                Volver a Ofertas Laborales
            </router-link>
        </v-col>
        <v-col cols="12" md="6" class="pl-8 pr-8">
            <titulo-principal-white-cx :titulo="data.puesto" />
            <v-card
                class="mx-auto my-4"
                max-width="100%"
            >
                <v-card-title>{{ data.puesto }}</v-card-title>

                <v-card-text>
                    <v-row
                        align="center"
                        class="mx-0"
                    >
                    <v-col cols="12">
                        <pre style="display: inline-block;overflow: auto;width:100%;white-space: pre-line;text-align: justify;text-justify: inter-word;">
                            {{ data.descripcion.trim() }}
                        </pre>    
                    </v-col>
                    </v-row>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>

                
                
            </v-card>
        </v-col>
        <v-col cols="12" md="6" class="pl-8 pr-8">
            <v-btn
              color="success"
              dark
              large
              block
              @click="openUrl"
            >
            Postular con mi CV  
                <v-icon right>
                    mdi-briefcase-plus
                </v-icon>
            </v-btn>
            <v-card
                class="mx-auto"
                style="margin-top: 28px;"
                max-width="100%"
            >
                <v-card-title>Detalle del puesto</v-card-title>

                <v-card-text>
                    <v-row
                        align="center"
                        class="mx-0"
                    >
                    <v-col cols="12">
                        <strong>ÁREA</strong><br/>
                        <v-chip
                            class="ma-2 w-full"
                            label
                            large
                            >
                            <v-icon left>
                                mdi-account-tie
                            </v-icon>
                            {{ data.area}}
                        </v-chip>
                        <br/>
                        <strong>UBICACIÓN</strong><br/>
                        <v-chip
                            class="ma-2 w-full"
                            label
                            large
                            >
                            <v-icon left>
                                mdi-map-marker
                            </v-icon>
                            {{ data.ubicacion}}
                        </v-chip>
                        <br/>
                        <strong>TIPO DE PUESTO</strong><br/>
                        <v-chip
                            class="ma-2 w-full"
                            label
                            large
                            >
                            <v-icon left>
                                mdi-clock-outline
                            </v-icon>
                            {{ data.tipopuesto}}
                        </v-chip>
                        <br/>
                        <strong>RANGO SALARIAL</strong><br/>
                        <v-chip
                            class="ma-2 w-full"
                            label
                            large
                            >
                            <v-icon left>
                                mdi-currency-usd
                            </v-icon>
                            {{ data.rangosalarial}}
                        </v-chip>
                        <br/>
                        <strong>FECHA DE PUBLICACIÓN</strong><br/>
                        <v-chip
                            class="ma-2 w-full"
                            label
                            large
                            >
                            <v-icon left>
                                mdi-calendar-range
                            </v-icon>
                            {{ processDate(data.fechapublicacion)}}
                        </v-chip>
                        <br/>
                        <div v-if="data.experienciaminimaexcluyente === 0">
                        <strong>EXPERIENCIA MÍNIMA</strong><br/>
                        <v-chip
                            class="ma-2 w-full"
                            label
                            large
                            >
                            <v-icon left>
                                mdi-wrench-clock
                            </v-icon>
                            {{ data.experienciaminima }} años
                        </v-chip>
                        <br/>
                        </div>
                        <div v-if="data.estudiorequeridoexcluyente === 0">
                        <strong>ESTUDIO REQUERIDO</strong><br/>
                        <v-chip
                            class="ma-2 w-full"
                            label
                            large
                            >
                            <v-icon left>
                                mdi-account-school
                            </v-icon>
                            {{ data.estudiorequerido }}
                        </v-chip>
                        <br/>
                        </div>
                        <div v-if="data.sexoexcluyente === 0">
                        <strong>SEXO</strong><br/>
                        <v-chip
                            class="ma-2 w-full"
                            label
                            large
                            >
                            <v-icon left>
                                mdi-gender-male-female-variant
                            </v-icon>
                            {{ data.sexo }}
                        </v-chip>
                        <br/>
                        </div>
                    </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-dialog v-model="dialogPostular" max-width="550px">
                <v-card>
                    <v-card-title class="headline">¿Está seguro que desea postular con su CV al puesto de {{ data.puesto }} ?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    
                    <v-btn color="error" @click="dialogPostular=false">
                        Cancelar
                    </v-btn>
                    <v-btn color="success" link :to="linkpostular">
                        Si, continuar
                    </v-btn>
                    
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </v-row>
</template>

<script>
import Api from '@/api';
import TituloPrincipalWhiteCx from '@/components/titulos/TituloPrincipalWhiteCx'
import { mapGetters } from 'vuex'


export default {
    name: 'OfertaEspecificaUsuario',
    components: { TituloPrincipalWhiteCx},
    data () {
        return {
            dialogPostular: false,
            data: {
                puesto: '',
                descripcion: '',
                area: '',
                ubicacion: '',
                tipopuesto: '',
                rangosalarial: '',
                fechapublicacion: '',
                experienciaminima: '',
                experienciaminimaexcluyente: 0,
                estudiorequerido: '',
                estudiorequeridoexcluyente: 0,
                sexo: '',
                sexoexcluyente: 0,

            },

        }
    },
    computed: {
        ...mapGetters(['system','baseurl']),
        
        urlCalificacion() {
            return `/hojadevida/${this.editedItemCalificacion._id}`
        },
        methodCalificacion() {
            return 'put'
        },
        linkpostular() {
            const ID_OFERTA = this.$route.params.id;
            return `/ofertas-laborales/${ID_OFERTA}/postular`
        },
        dataCalificacion() {
            return {
                presencia: this.editedItemCalificacion.presencia,
                contactos: this.editedItemCalificacion.contactos,
                verificado: this.editedItemCalificacion.verificado
            }
        }
    },
    watch: {

        
      
    },
    created() {
        
        this.readDataFromAPI();
        //this.$store.dispatch('setItemSelected','AdministrarPublicaciones_listar')
    },
    methods: {
        processDate (valor) {
            const date = new Date(valor)
            const year = date.getFullYear()
            let month = date.getMonth()+1
            let dt = date.getDate()
        
            if (dt < 10) {
            dt = '0' + dt
            }
            if (month < 10) {
            month = '0' + month
            }
        
            return dt+'-' + month + '-'+year
        },


      openUrl() {
        window.open('https://app.jxbs.ai/', '_blank');
      },
        
        async readDataFromAPI() {
            const ID_OFERTA = this.$route.params.id;
            this.loading = true;
            
            let headers = { 'Content-Type': 'application/json' }
            const api = new Api(this.baseurl,'get',headers,{})
            let response = await api.call_respuesta_directa(`/postulacion-trabajo/public/${ID_OFERTA}`) 
            this.data = response.data ? response.data.data : this.data;
            this.loading = false;
            
            
        },

        
    }
}
</script>

<style scoped>
    tbody tr:nth-child(odd) {
        background-color:rgba(79, 125, 147, 10%);
    }
</style>