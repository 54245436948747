<template>
    <v-row>
        <v-col cols="12" class="py-0">
            <titulo-principal-cx :titulo="titulo" />
        </v-col>
        <v-col cols="12" class="py-0">
            <v-data-table
                no-data-text="Reportes no disponibles"
                no-results-text="Resultados no encontrados"
                :page="page"
                :pageCount="numberOfPages"
                :headers="headers"
                :items="reportes"
                :options.sync="options"
                :server-items-length="totalReportes"
                :loading="loading"
                :search="search"
                class="elevation-1"
            >
                <template v-slot:top>
                    <v-btn block large @click="readDataFromAPI">
                        Refrescar
                        <v-icon large right dark>mdi-refresh</v-icon>
                    </v-btn>
                </template>
                <template v-slot:[`item.estado`]="{ item }">
                    <span v-if="item.estado === 'P'"><v-icon small>mdi-timer-sand</v-icon> Pendiente</span>
                    <span v-if="item.estado === 'C'"><v-icon small>mdi-check-bold</v-icon> Completado</span>
                    <span v-if="item.estado === 'G'"><v-icon small>mdi-file-pdf-box</v-icon> Generado PDF</span>
                    <span v-if="item.estado === 'E'"><v-icon small>mdi-send-check</v-icon> Enviado</span>
                    <span v-if="item.estado === 'F'"><v-icon small>mdi-file-sign</v-icon> Firmado</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <router-link title="Generar PDF" v-if="item.dataOut" :to="{ name: item.tipo === 'clima_laboral' ? 'ReporteClima' : (item.tipo === 'diagnostico_motivacional' ? 'ReporteDiagnostico' : (item.tipo === 'postcovid' ? 'ReportePostCovid' :(item.tipo === 'desempeno' ? 'ReporteDesempenoGeneral' : (item.tipo === 'desempeno_especifico' ? 'ReporteDesempenoEspecifico' : '')))), params: { dataOut: item.dataOut, dataIn: item.dataIn, paso: 2, id_reporte_pendiente: item._id}}" >
                        <v-icon :color="system.colores.icons_2">mdi-chart-bar</v-icon>
                    </router-link>
                    <v-btn v-if="item.estado === 'G' || item.estado === 'E' || item.estado === 'F'" :href="item.url_pdf_generado" target="_blank" icon :title="item.estado === 'F' ? 'Ver PDF firmado' : 'Ver PDF'">
                        <v-icon :color="system.colores.icons_2">mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <v-btn title="Enviar a usuario" @click="enviarUsuario(item._id)" icon v-if="item.estado === 'G'">
                        <v-icon :color="system.colores.icons_2">mdi-send</v-icon>
                    </v-btn>
                    <v-btn title="Reenviar a usuario" @click="enviarUsuario(item._id)" icon v-if="item.estado === 'E'">
                        <v-icon :color="system.colores.icons_2">mdi-send</v-icon>
                    </v-btn>
                    
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>

<script>
import Api from '@/api';
import Cookies from 'js-cookie'
import TituloPrincipalCx from '@/components/titulos/TituloPrincipalCx'
import { mapGetters } from 'vuex'


export default {
    name: 'ReportesPendientes',
    components: { TituloPrincipalCx },
    data () {
        return {
            titulo: "Reportes pendientes",
            page: 1,
            totalReportes: 0,
            numberOfPages: 0,
            loading: true,
            search: '',
            options: {},
            reportes: [],
            dialogDelete: false,
            headers: [
                {
                    text: 'Tipo evaluación',
                    align: 'start',
                    sortable: false,
                    value: 'tipo',
                },
                {
                    text: 'Evaluación',
                    align: 'start',
                    sortable: false,
                    value: 'evaluacion',
                },
                {
                    text: 'Empresa',
                    align: 'start',
                    sortable: false,
                    value: 'empresa',
                },
                {
                    text: 'Usuario',
                    align: 'start',
                    sortable: false,
                    value: 'usuario',
                },
                {
                    text: 'Dirección',
                    align: 'start',
                    sortable: false,
                    value: 'direccion',
                },
                {
                    text: 'Estado',
                    align: 'start',
                    sortable: false,
                    value: 'estado',
                },
                { text: 'Acciones', value: 'actions', sortable: false },
            ]
        }
    },
    computed: {
        ...mapGetters(['system','baseurl'])
    },
    watch: {
      options: {
        handler() {
            this.readDataFromAPI();
        },
      },  
      dialogDelete (val) {
        val || this.closeDelete()
      },
      search: {
          handler() {
            this.readDataFromAPI();
        },
      }
    },
    created() {
        this.readDataFromAPI()
        //this.$store.dispatch('setItemSelected','empresas_listar')
    },
    methods: {

        async readDataFromAPI() {
            this.loading = true;
            const { page, itemsPerPage } = this.options;
            let pageNumber = page - 1;
            let params = {
                size: itemsPerPage,
                page: pageNumber
            }
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            let reportes = []
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers,{},params)
                let response = await api.call_respuesta_directa('/reportes/pendientes') 
                reportes = response.data ? response.data.data : [];
                this.loading = false;
                this.totalReportes = response.data.totalReportes;
                this.numberOfPages = response.data.totalPages;
            }
            this.reportes = reportes
        },

        async enviarUsuario(id) {
            this.loading = true;
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'put',headers,{},{})
                let response = await api.call_respuesta_directa(`/reportes/${id}/enviar`) 
                console.dir(response)
                //reportes = response.data ? response.data.data : [];
                this.loading = false;
                await this.readDataFromAPI();
            }
        }

        

        
    }
}
</script>

<style scoped>
    tbody tr:nth-child(odd) {
        background-color:rgba(79, 125, 147, 10%);
    }
</style>