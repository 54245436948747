<template>
    <v-row>
        <v-col cols="12" class="py-0">
            <titulo-principal-cx :titulo="titulo" />
        </v-col>
        <v-col cols="12" class="py-0">
            <v-stepper v-model="step" vertical>
                <v-stepper-step step="1" class="cursor-pointer" @click="step=1" :complete="false">
                    <v-icon class="mr-2" :style="{flex:'none'}" :color="system.colores.icons_2">mdi-account</v-icon> Datos Personales
                </v-stepper-step>
                <v-stepper-content step="1">
                    <form-cx action="#" :multipart="multipart" :method="methodDatosPersonales" :data="dataDatosPersonales" :url="urlDatosPersonales" @success="success" @error="error">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="data.datospersonales.nombre"
                                    :rules="rules.datospersonales.nombre.rule"
                                    :counter="rules.datospersonales.nombre.counter"
                                    label="Nombres"
                                    required
                                ></v-text-field>
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="data.datospersonales.fecha_nacimiento"
                                            label="Fecha de Nacimiento"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        ref="picker"
                                        v-model="data.datospersonales.fecha_nacimiento"
                                        :max="new Date().toISOString().substr(0, 10)"
                                        min="1950-01-01"
                                        @change="save"
                                        ></v-date-picker>
                                </v-menu>
                                <v-text-field
                                    v-model="data.datospersonales.nacionalidad"
                                    :rules="rules.datospersonales.nacionalidad.rule"
                                    :counter="rules.datospersonales.nacionalidad.counter"
                                    label="Nacionalidad"
                                    required
                                ></v-text-field>
                                <v-select
                                    :items="items_tipos_documento"
                                    label="Tipo de documento"
                                    v-model="data.datospersonales.tipo_documento"
                                ></v-select>
                                <v-checkbox
                                    v-model="data.datospersonales.poseo_licencia"
                                    label="Poseo licencia"
                                    hide-details
                                ></v-checkbox>
                                <v-checkbox
                                    v-model="data.datospersonales.poseo_discapacidad"
                                    label="Poseo algún tipo de discapacidad"
                                    hide-details
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="data.datospersonales.apellidos"
                                    :rules="rules.datospersonales.apellidos.rule"
                                    :counter="rules.datospersonales.apellidos.counter"
                                    label="Apellido"
                                    required
                                ></v-text-field>
                                <v-select
                                    :items="items_genero"
                                    label="Género"
                                    v-model="data.datospersonales.genero"
                                ></v-select>
                                <v-select
                                    :items="items_estado_civil"
                                    label="Estado civil"
                                    v-model="data.datospersonales.estado_civil"
                                ></v-select>
                                <v-text-field
                                    v-model="data.datospersonales.numero_documento"
                                    :rules="rules.datospersonales.numero_documento.rule"
                                    :counter="rules.datospersonales.numero_documento.counter"
                                    label="Número de documento"
                                    required
                                ></v-text-field>
                                <v-checkbox
                                    v-model="data.datospersonales.poseo_movilidad"
                                    label="Poseo movilidad propia"
                                    hide-details
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-file-input
                                    v-if="!exist_logo"
                                    :rules="rules.file.rule"
                                    accept="image/png, image/jpeg, image/bmp"
                                    placeholder="(Recomendado 160 x 100)"
                                    prepend-icon="mdi-camera"
                                    v-model="data.image"
                                    label="Foto"
                                    required
                                ></v-file-input>
                                <v-btn v-if="exist_logo" @click="exist_logo = false" depressed color="primary">Cambiar Foto</v-btn>
                                <v-btn v-if="!exist_logo && filepathfull" @click="exist_logo = true" text>Cancelar</v-btn>
                            </v-col>
                            <v-col cols="12" md="6">
                                <p>FOTO:</p>
                                <img width="160" height="100" :src="filepathfull" />
                            </v-col>
                            
                        </v-row>
                        <v-btn v-if="step > 1" @click="step=step-1" color="green" class="mr-4">
                            &#8592;Regresar
                        </v-btn>
                        <submit-cx :block="false" class="mr-4" classBtn="mt-0"><v-icon left>mdi-content-save</v-icon> Guardar</submit-cx>
                        <v-btn v-if="step < 8" @click="step=step+1" color="green" class="mr-4">
                            Continuar&#8594;
                        </v-btn>
                    </form-cx>
                    
                    
                        
                </v-stepper-content>

                <v-stepper-step step="2" class="cursor-pointer" @click="step=2" :complete="false">
                    <v-icon class="mr-2" :style="{flex:'none'}" :color="system.colores.icons_2">mdi-card-account-phone</v-icon> Datos de Contacto
                </v-stepper-step>
                <v-stepper-content step="2">
                    <form-cx action="#" :method="methodDatosContacto" :data="dataDatosContacto" :url="urlDatosContacto" @success="success" @error="error">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="data.datoscontacto.celular_numero"
                                    :rules="rules.datoscontacto.celular_numero.rule"
                                    :counter="rules.datoscontacto.celular_numero.counter"
                                    label="Teléfono celular"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-model="data.datoscontacto.email"
                                    :rules="rules.datoscontacto.email.rule"
                                    type="email"
                                    label="Email"
                                    required
                                ></v-text-field>

                                
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="data.datoscontacto.telefono_numero"
                                    :rules="rules.datoscontacto.telefono_numero.rule"
                                    :counter="rules.datoscontacto.telefono_numero.counter"
                                    label="Teléfono fijo"
                                    required
                                ></v-text-field>
                            </v-col>
                            
                            
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-select
                                    :items="items_pais"
                                    label="País"
                                    readonly
                                    v-model="data.datoscontacto.pais"
                                ></v-select>
                                <v-select
                                    :items="items_localidades"
                                    label="Localidad"
                                    v-model="data.datoscontacto.localidad_id"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    :items="items_provincias"
                                    label="Provincia"
                                    v-model="data.datoscontacto.provincia_id"
                                ></v-select>
                                <v-text-field
                                    v-model="data.datoscontacto.calle"
                                    :rules="rules.datoscontacto.calle.rule"
                                    :counter="rules.datoscontacto.calle.counter"
                                    label="Calle"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-btn v-if="step > 1" @click="step=step-1" color="green" class="mr-4">
                            &#8592;Regresar
                        </v-btn>
                        <submit-cx :block="false" class="mr-4" classBtn="mt-0"><v-icon left>mdi-content-save</v-icon> Guardar</submit-cx>
                        <v-btn v-if="step < 8" @click="step=step+1" color="green" class="mr-4">
                            Continuar&#8594;
                        </v-btn>
                    </form-cx>    
                </v-stepper-content>

                <v-stepper-step step="3" class="cursor-pointer" @click="step=3" :complete="false">
                    <v-icon class="mr-2" :style="{flex:'none'}" :color="system.colores.icons_2">mdi-file-star</v-icon> Preferencias Laborales
                </v-stepper-step>
                <v-stepper-content step="3">
                    <form-cx action="#" :method="methodPreferenciaLaboral" :data="dataPreferenciaLaboral" :url="urlPreferenciaLaboral" @success="success" @error="error">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="data.preferencia_laboral.salario_bruto_pretendido"
                                    label="Indicar salario bruto pretendido"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-textarea
                                    solo
                                    name="input-7-4"
                                    label="Objetivo laboral"
                                    :rules="rules.preferencia_laboral.objetivo_laboral.rule"
                                    v-model="data.preferencia_laboral.objetivo_laboral"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-btn v-if="step > 1" @click="step=step-1" color="green" class="mr-4">
                            &#8592;Regresar
                        </v-btn>
                        <submit-cx :block="false" class="mr-4" classBtn="mt-0"><v-icon left>mdi-content-save</v-icon> Guardar</submit-cx>
                        <v-btn v-if="step < 8" @click="step=step+1" color="green" class="mr-4">
                            Continuar&#8594;
                        </v-btn>
                    </form-cx>
                </v-stepper-content>

                <v-stepper-step step="4" class="cursor-pointer" @click="step=4" :complete="false">
                    <v-icon class="mr-2" :style="{flex:'none'}" :color="system.colores.icons_2">mdi-account-hard-hat</v-icon> Experiencias Laborales
                </v-stepper-step>
                <v-stepper-content step="4">
                    <v-data-table 
                        :headers="headers_experiencia_laboral"
                        :items="data.experiencias_laborales" 
                        no-data-text="Ninguna experiencia laboral"
                        no-results-text="Resultados no encontrados"
                        :page="pageExperiencias"
                        :pageCount="numberOfPagesExperiencias"
                        :options.sync="optionsexperiencias"
                        :server-items-length="totalExperiencias"
                        :loading="loadingExperiencias"
                        :search="searchExperiencias"
                        class="elevation-1">


                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-dialog v-model="dialogExperiencias" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        Agregar nueva
                                        </v-btn>
                                    </template>
                                    <form-cx action="#" :method="methodExperiencia" :data="editedItemExperiencias" :url="urlExperiencia" 
                                        @success="successExperiencia" 
                                        @error="errorExperiencia">
                                    <v-card>
                                        <v-card-title>
                                        <span class="text-h5">{{ formTitleExperiencias }}</span>
                                        </v-card-title>
                                        <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    <v-text-field
                                                        v-model="editedItemExperiencias.nombre_empresa"
                                                        :rules="rules.experiencias_laborales.nombre_empresa.rule"
                                                        :counter="rules.experiencias_laborales.nombre_empresa.counter"
                                                        label="Nombre de la empresa"
                                                        required
                                                    ></v-text-field>
                                                    <v-text-field
                                                        v-model="editedItemExperiencias.puesto"
                                                        :rules="rules.experiencias_laborales.puesto.rule"
                                                        :counter="rules.experiencias_laborales.puesto.counter"
                                                        label="Puesto"
                                                        required
                                                    ></v-text-field>
                                                    <v-menu
                                                        ref="menuDesdeExperiencias"
                                                        v-model="menuDesdeExperiencias"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="editedItemExperiencias.fecha_desde"
                                                                label="Fecha desde"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            ref="picker"
                                                            v-model="editedItemExperiencias.fecha_desde"
                                                            :max="new Date().toISOString().substr(0, 10)"
                                                            min="1950-01-01"
                                                            @change="saveFechaDesdeExperiencias"
                                                            ></v-date-picker>
                                                    </v-menu>
                                                    <v-select
                                                        :items="items_areas"
                                                        label="Área"
                                                        v-model="editedItemExperiencias.area_puesto_id"
                                                    ></v-select>
                                                    
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-select
                                                        :items="items_actividades"
                                                        label="Actividad de la empresa"
                                                        v-model="editedItemExperiencias.actividad_empresa"
                                                    ></v-select>
                                                    <v-text-field
                                                        v-model="editedItemExperiencias.pais"
                                                        :rules="rules.experiencias_laborales.pais.rule"
                                                        :counter="rules.experiencias_laborales.pais.counter"
                                                        label="País"
                                                        required
                                                    ></v-text-field>
                                                    <v-row>
                                                        <v-col cols="12" md="9">
                                                            <v-menu
                                                                ref="menuHastaExperiencias"
                                                                v-model="menuHastaExperiencias"
                                                                :close-on-content-click="false"
                                                                transition="scale-transition"
                                                                offset-y
                                                                min-width="auto"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        v-model="editedItemExperiencias.fecha_hasta"
                                                                        label="Fecha hasta"
                                                                        readonly
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    ></v-text-field>
                                                                    
                                                                </template>
                                                                <v-date-picker
                                                                    ref="picker"
                                                                    v-model="editedItemExperiencias.fecha_hasta"
                                                                    :max="new Date().toISOString().substr(0, 10)"
                                                                    min="1950-01-01"
                                                                    @change="saveFechaHastaExperiencias"
                                                                    ></v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                        <v-col cols="12" md="3">
                                                            <v-checkbox
                                                                        v-model="editedItemExperiencias.al_presente"
                                                                        label="Al presente"
                                                                        hide-details
                                                                    ></v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model="editedItemExperiencias.persona_acargo"
                                                        type="number"
                                                        label="Personas a cargo"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-textarea
                                                        solo
                                                        
                                                        name="input-7-4"
                                                        label="Responsabilidades"
                                                        :rules="rules.experiencias_laborales.responsabilidades.rule"
                                                        v-model="editedItemExperiencias.responsabilidades"
                                                    ></v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeExperiencias">
                                            Cancelar
                                        </v-btn>
                                        <submit-cx tipoButton="text" classBtn="mt-0">Agregar</submit-cx>
                                        </v-card-actions>
                                    </v-card>
                                    </form-cx>
                                </v-dialog>
                                <v-dialog v-model="dialogDeleteExperiencias" max-width="500px">
                                    <v-card>
                                        <v-card-title class="text-h5">¿Estás seguro de eliminar?</v-card-title>
                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeDeleteExperiencias">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="deleteItemConfirmExperiencias">OK</v-btn>
                                        <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="editItemExperiencias(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteItemExperiencias(item)"
                            >
                                mdi-delete
                            </v-icon>
                            </template>
                    </v-data-table>
                    <br/>
                    <v-btn v-if="step > 1" @click="step=step-1" color="green" class="mr-4">
                        &#8592;Regresar
                    </v-btn>
                    <v-btn v-if="step < 8" @click="step=step+1" color="green" class="mr-4">
                        Continuar&#8594;
                    </v-btn>
                </v-stepper-content>

                <v-stepper-step step="5" class="cursor-pointer" @click="step=5" :complete="false">
                    <v-icon class="mr-2" :style="{flex:'none'}" :color="system.colores.icons_2">mdi-account-school</v-icon> Educación
                </v-stepper-step>
                <v-stepper-content step="5">
                    <v-data-table 
                        :headers="headers_educacion"
                        :items="data.educaciones" 
                        no-data-text="Ninguna experiencia laboral"
                        no-results-text="Resultados no encontrados"
                        :page="pageEducacion"
                        :pageCount="numberOfPagesEducacion"
                        :options.sync="optionseducacion"
                        :server-items-length="totalEducacion"
                        :loading="loadingEducacion"
                        :search="searchEducacion"
                        class="elevation-1">


                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-dialog v-model="dialogEducacion" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        Agregar nueva
                                        </v-btn>
                                    </template>
                                    <form-cx action="#" :method="methodEducacion" :data="editedItemEducacion" :url="urlEducacion" 
                                        @success="successEducacion" 
                                        @error="errorEducacion">
                                    <v-card>
                                        <v-card-title>
                                        <span class="text-h5">{{ formTitleEducacion }}</span>
                                        </v-card-title>
                                        <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    <v-text-field
                                                        v-model="editedItemEducacion.titulo"
                                                        :rules="rules.educacion.titulo.rule"
                                                        :counter="rules.educacion.titulo.counter"
                                                        label="Título"
                                                        required
                                                    ></v-text-field>
                                                    <v-select
                                                        :items="items_areas_educacion"
                                                        label="Área estudio"
                                                        v-model="editedItemEducacion.area_estudio"
                                                    ></v-select>
                                                    <v-select
                                                        :items="items_tipos_estudio"
                                                        label="Tipo estudio"
                                                        v-model="editedItemEducacion.tipo_estudio"
                                                    ></v-select>
                                                    <v-menu
                                                        ref="menuDesdeEducacion"
                                                        v-model="menuDesdeEducacion"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="editedItemEducacion.fecha_desde"
                                                                label="Fecha desde"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            ref="picker"
                                                            v-model="editedItemEducacion.fecha_desde"
                                                            :max="new Date().toISOString().substr(0, 10)"
                                                            min="1950-01-01"
                                                            @change="saveFechaDesdeEducacion"
                                                            ></v-date-picker>
                                                    </v-menu>
                                                    
                                                    
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field
                                                        v-model="editedItemEducacion.institucion"
                                                        :rules="rules.educacion.institucion.rule"
                                                        :counter="rules.educacion.institucion.counter"
                                                        label="Institución"
                                                        required
                                                    ></v-text-field>
                                                    <v-text-field
                                                        v-model="editedItemEducacion.pais"
                                                        :rules="rules.educacion.pais.rule"
                                                        :counter="rules.educacion.pais.counter"
                                                        label="País"
                                                        required
                                                    ></v-text-field>
                                                        <v-select
                                                        :items="items_estados_estudio"
                                                        label="Estado"
                                                        v-model="editedItemEducacion.estado"
                                                    ></v-select>
                                                    <v-row>
                                                        <v-col cols="12" md="9">
                                                            <v-menu
                                                                ref="menuHastaEducacion"
                                                                v-model="menuHastaEducacion"
                                                                :close-on-content-click="false"
                                                                transition="scale-transition"
                                                                offset-y
                                                                min-width="auto"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        v-model="editedItemEducacion.fecha_hasta"
                                                                        label="Fecha hasta"
                                                                        readonly
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    ></v-text-field>
                                                                    
                                                                </template>
                                                                <v-date-picker
                                                                    ref="picker"
                                                                    v-model="editedItemEducacion.fecha_hasta"
                                                                    :max="new Date().toISOString().substr(0, 10)"
                                                                    min="1950-01-01"
                                                                    @change="saveFechaHastaEducacion"
                                                                    ></v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                        <v-col cols="12" md="3">
                                                            <v-checkbox
                                                                        v-model="editedItemEducacion.al_presente"
                                                                        label="Al presente"
                                                                        hide-details
                                                                    ></v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                    
                                                </v-col>
                                                
                                            </v-row>
                                        </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeEducacion">
                                            Cancelar
                                        </v-btn>
                                        <submit-cx tipoButton="text" classBtn="mt-0">Guardar</submit-cx>
                                        </v-card-actions>
                                    </v-card>
                                    </form-cx>
                                </v-dialog>
                                <v-dialog v-model="dialogDeleteEducacion" max-width="500px">
                                    <v-card>
                                        <v-card-title class="text-h5">¿Estás seguro de eliminar?</v-card-title>
                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeDeleteEducacion">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="deleteItemConfirmEducacion">OK</v-btn>
                                        <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="editItemEducacion(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteItemEducacion(item)"
                            >
                                mdi-delete
                            </v-icon>
                            </template>
                    </v-data-table>
                    <br/>
                    <v-btn v-if="step > 1" @click="step=step-1" color="green" class="mr-4">
                        &#8592;Regresar
                    </v-btn>
                    <v-btn v-if="step < 8" @click="step=step+1" color="green" class="mr-4">
                        Continuar&#8594;
                    </v-btn>
                </v-stepper-content>

                <v-stepper-step step="6" class="cursor-pointer" @click="step=6" :complete="false">
                    <v-icon class="mr-2" :style="{flex:'none'}" :color="system.colores.icons_2">mdi-account-voice</v-icon> Idioma
                </v-stepper-step>
                <v-stepper-content step="6">
                    <v-data-table 
                        :headers="headers_idiomas"
                        :items="data.idiomas" 
                        no-data-text="Ningún idioma"
                        no-results-text="Resultados no encontrados"
                        :page="pageIdiomas"
                        :pageCount="numberOfPagesIdiomas"
                        :options.sync="optionsidiomas"
                        :server-items-length="totalIdiomas"
                        :loading="loadingIdiomas"
                        :search="searchIdiomas"
                        class="elevation-1">


                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-dialog v-model="dialogIdiomas" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        Agregar nueva
                                        </v-btn>
                                    </template>
                                    <form-cx action="#" :method="methodIdiomas" :data="editedItemIdioma" :url="urlIdiomas" 
                                        @success="successIdiomas" 
                                        @error="errorIdiomas">
                                    <v-card>
                                        <v-card-title>
                                        <span class="text-h5">{{ formTitleIdiomas }}</span>
                                        </v-card-title>
                                        <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    
                                                    <v-select
                                                        :items="items_idiomas"
                                                        label="Idioma"
                                                        v-model="editedItemIdioma.idioma"
                                                    ></v-select>
                                                    <v-select
                                                        :items="items_nivel_oral_idiomas"
                                                        label="Nivel oral"
                                                        v-model="editedItemIdioma.nivel_oral"
                                                    ></v-select>
                                                    
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    
                                                    <v-select
                                                        :items="items_nivel_escrito_idiomas"
                                                        label="Nivel escrito"
                                                        v-model="editedItemIdioma.nivel_escrito"
                                                    ></v-select>
                                                    
                                                    
                                                </v-col>
                                                
                                            </v-row>
                                        </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeIdiomas">
                                            Cancelar
                                        </v-btn>
                                        <submit-cx tipoButton="text" classBtn="mt-0">Guardar</submit-cx>
                                        </v-card-actions>
                                    </v-card>
                                    </form-cx>
                                </v-dialog>
                                <v-dialog v-model="dialogDeleteIdiomas" max-width="500px">
                                    <v-card>
                                        <v-card-title class="text-h5">¿Estás seguro de eliminar?</v-card-title>
                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeDeleteIdiomas">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="deleteItemConfirmIdiomas">OK</v-btn>
                                        <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="editItemIdiomas(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteItemIdiomas(item)"
                            >
                                mdi-delete
                            </v-icon>
                            </template>
                    </v-data-table>
                    <br/>
                    <v-btn v-if="step > 1" @click="step=step-1" color="green" class="mr-4">
                        &#8592;Regresar
                    </v-btn>
                    <v-btn v-if="step < 8" @click="step=step+1" color="green" class="mr-4">
                        Continuar&#8594;
                    </v-btn>
                </v-stepper-content>

                <v-stepper-step step="7" class="cursor-pointer" @click="step=7" :complete="false">
                    <v-icon class="mr-2" :style="{flex:'none'}" :color="system.colores.icons_2">mdi-desktop-classic</v-icon> Informática
                </v-stepper-step>
                <v-stepper-content step="7">
                    <v-data-table 
                        :headers="headers_informatica"
                        :items="data.informaticas" 
                        no-data-text="Ninguna opción de informática"
                        no-results-text="Resultados no encontrados"
                        :page="pageInformatica"
                        :pageCount="numberOfPagesInformatica"
                        :options.sync="optionsinformatica"
                        :server-items-length="totalInformatica"
                        :loading="loadingInformatica"
                        :search="searchInformatica"
                        class="elevation-1">


                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-dialog v-model="dialogInformatica" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        Agregar nueva
                                        </v-btn>
                                    </template>
                                    <form-cx action="#" :method="methodInformatica" :data="editedItemInformatica" :url="urlInformatica" 
                                        @success="successInformatica" 
                                        @error="errorInformatica">
                                    <v-card>
                                        <v-card-title>
                                        <span class="text-h5">{{ formTitleInformatica }}</span>
                                        </v-card-title>
                                        <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    
                                                    <v-select
                                                        :items="items_areas_informatica"
                                                        label="Área"
                                                        v-model="editedItemInformatica.area"
                                                    ></v-select>
                                                    <v-text-field
                                                        v-model="editedItemInformatica.conocimiento"
                                                        :rules="rules.informatica.conocimiento.rule"
                                                        :counter="rules.informatica.conocimiento.counter"
                                                        label="Conocimiento"
                                                        required
                                                    ></v-text-field>
                                                    
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    
                                                    <v-select
                                                        :items="items_nivel_informatica"
                                                        label="Nivel"
                                                        v-model="editedItemInformatica.nivel"
                                                    ></v-select>
                                                    
                                                    
                                                </v-col>
                                                
                                            </v-row>
                                        </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeInformatica">
                                            Cancelar
                                        </v-btn>
                                        <submit-cx tipoButton="text" classBtn="mt-0">Guardar</submit-cx>
                                        </v-card-actions>
                                    </v-card>
                                    </form-cx>
                                </v-dialog>
                                <v-dialog v-model="dialogDeleteInformatica" max-width="500px">
                                    <v-card>
                                        <v-card-title class="text-h5">¿Estás seguro de eliminar?</v-card-title>
                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeDeleteInformatica">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="deleteItemConfirmInformatica">OK</v-btn>
                                        <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="editItemInformatica(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteItemInformatica(item)"
                            >
                                mdi-delete
                            </v-icon>
                            </template>
                    </v-data-table>
                    <br/>
                    <v-btn v-if="step > 1" @click="step=step-1" color="green" class="mr-4">
                        &#8592;Regresar
                    </v-btn>
                    <v-btn v-if="step < 8" @click="step=step+1" color="green" class="mr-4">
                        Continuar&#8594;
                    </v-btn>
                </v-stepper-content>

                <v-stepper-step step="8" class="cursor-pointer" @click="step=8" :complete="false">
                    <v-icon class="mr-2" :style="{flex:'none'}" :color="system.colores.icons_2">mdi-human-male-board</v-icon> Otros conocimientos
                </v-stepper-step>
                <v-stepper-content step="8">
                    <v-data-table 
                        :headers="headers_otros"
                        :items="data.otros_conocimientos" 
                        no-data-text="Ninguna opción de otros conocimientos"
                        no-results-text="Resultados no encontrados"
                        :page="pageOtros"
                        :pageCount="numberOfPagesOtros"
                        :options.sync="optionsotros"
                        :server-items-length="totalOtros"
                        :loading="loadingOtros"
                        :search="searchOtros"
                        class="elevation-1">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-dialog v-model="dialogOtros" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        Agregar nueva
                                        </v-btn>
                                    </template>
                                    <form-cx action="#" :method="methodOtros" :data="editedItemOtros" :url="urlOtros" 
                                        @success="successOtros" 
                                        @error="errorOtros">
                                    <v-card>
                                        <v-card-title>
                                        <span class="text-h5">{{ formTitleOtros }}</span>
                                        </v-card-title>
                                        <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    <v-text-field
                                                        v-model="editedItemOtros.titulo"
                                                        :rules="rules.otros.titulo.rule"
                                                        :counter="rules.otros.titulo.counter"
                                                        label="Título"
                                                        required
                                                    ></v-text-field>
                                                    
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-textarea
                                                        solo
                                                        name="input-7-4"
                                                        label="Descripción"
                                                        :rules="rules.otros.descripcion.rule"
                                                        v-model="editedItemOtros.descripcion"
                                                    ></v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeOtros">
                                            Cancelar
                                        </v-btn>
                                        <submit-cx tipoButton="text" classBtn="mt-0">Guardar</submit-cx>
                                        </v-card-actions>
                                    </v-card>
                                    </form-cx>
                                </v-dialog>
                                <v-dialog v-model="dialogDeleteOtros" max-width="500px">
                                    <v-card>
                                        <v-card-title class="text-h5">¿Estás seguro de eliminar?</v-card-title>
                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeDeleteOtros">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="deleteItemConfirmOtros">OK</v-btn>
                                        <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="editItemOtros(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteItemOtros(item)"
                            >
                                mdi-delete
                            </v-icon>
                            </template>
                    </v-data-table>
                    <br/>
                    <v-btn v-if="step > 1" @click="step=step-1" color="green" class="mr-4">
                        &#8592;Regresar
                    </v-btn>
                    <v-btn v-if="step < 8" @click="step=step+1" color="green" class="mr-4">
                        Continuar&#8594;
                    </v-btn>
                </v-stepper-content>

                
                
            </v-stepper>




            
        </v-col>
    </v-row>
</template>>

<script>

import Api from '@/api';
import Cookies from 'js-cookie'
import TituloPrincipalCx from '@/components/titulos/TituloPrincipalCx'
import FormCx from '@/components/forms/FormCx'
import SubmitCx from '@/components/forms/SubmitCx'
/*import MapboxMap from '@studiometa/vue-mapbox-gl/dist/components/MapboxMap';
import { Marker } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';*/

import { mapGetters } from 'vuex'

export default {
    name: 'HojaDeVida',
    data () {
        return {
            local: false,
            multipart: true,
            exist_logo: false,
            buttonCambiar: false,
            buttonCancelar: false,
            step: 1,

            dialogExperiencias: false,
            dialogDeleteExperiencias: false,
            pageExperiencias: 1,
            totalExperiencias: 0,
            numberOfPagesExperiencias: 0,
            loadingExperiencias: true,
            searchExperiencias: '',
            optionsexperiencias: {},

            dialogEducacion: false,
            dialogDeleteEducacion: false,
            pageEducacion: 1,
            totalEducacion: 0,
            numberOfPagesEducacion: 0,
            loadingEducacion: true,
            searchEducacion: '',
            optionseducacion: {},

            dialogIdiomas: false,
            dialogDeleteIdiomas: false,
            pageIdiomas: 1,
            totalIdiomas: 0,
            numberOfPagesIdiomas: 0,
            loadingIdiomas: true,
            searchIdiomas: '',
            optionsidiomas: {},

            dialogInformatica: false,
            dialogDeleteInformatica: false,
            pageInformatica: 1,
            totalInformatica: 0,
            numberOfPagesInformatica: 0,
            loadingInformatica: true,
            searchInformatica: '',
            optionsinformatica: {},

            dialogOtros: false,
            dialogDeleteOtros: false,
            pageOtros: 1,
            totalOtros: 0,
            numberOfPagesOtros: 0,
            loadingOtros: true,
            searchOtros: '',
            optionsotros: {},

            items_tipos_documento: ['Documento de identidad', 'Pasaporte'],
            items_genero: ['Femenino','Masculino','Otro'],
            items_estado_civil: ['Soltero/a','Casado/a','Unión Libre','Divorciado','Pareja de Hecho','Viudo'],
            items_pais: ['Ecuador'],
            items_provincias: [],
            items_localidades: [],
            items_actividades: 
            ['AFJP','Agro-Industrial','Agropecuaria','Alimenticia','Arquitectura','Artesanal','Automotriz','Aérea',
            'Banca / Financiera','Biotecnología','Comercio','Comercio Exterior','Construcción','Consultoría','Consumo masivo',
            'Correo','Defensa','Diseño','Editorial','Educación','Energía','Entretenimiento','Farmacéutica','Ferroviaria',
            'Financiera','Forestal','Ganadería','Gastronomía','Gobierno','Higiene y Perfumería','Holding','Hotelería',
            'Imprenta','Información e Investigación','Informática / Tecnología','Inmobiliaria','Internet','Jurídica',
            'Laboratorio','Manufactura','Medios','Minería / Petróleo / Gas','ONGs','Otra','Papelera','Pesca','Petroquímica',
            'Plásticos','Publicidad / Marketing / RRPP','Química','Retail','Salud','Seguros','Servicios','Siderurgia',
            'Supermercado / Hipermercado','Tabacalera','Telecomunicaciones','Textil','Transportadora','Transporte','Turismo'],
            items_areas_educacion: [
                'Abogacía / Derecho / Leyes','Actuaría','Acuicultura','Adm. de Empresas','Adm. y Gestión Pública','Administración Agropecuaria',
                'Agrimensor','Agronegocios','Antropología','Análisis de Sistemas','Apoderado Aduanal','Arqueología','Arquitectura',
                'Asesoría Legal Internacional','Asesoría en Comercio Exterior','Asesoría en Comercio Exterior Jubilado','Asistente de Tráfico',
                'Astronomía','Bachiller','Bacteriología','Bellas Artes','Bibliotecología','BioFisica','Bioingeniería','Biología',
                'Bioquímica','Biotecnología','Call Center','Capacitación Comercio Exterior','Cartografía','Ciencias Físicas',
                'Ciencias Políticas','Ciencias de la Educación','Ciencias del Ejercicio / Educacion Física','Comercio Int./Ext.',
                'Compras Internacionales/Importación','Computación / Informática','Comunicación Audiovisual','Construcción / Obras Civiles',
                'Consultorías Comercio Exterior','Contabilidad / Auditoría','Dibujo Técnico','Diseño Gráfico','Diseño Industrial',
                'Diseño Web','Diseño de Imagen y Sonido','Diseño de Vestuario / Textil / Modas','Ecología','Economía','Educacion',
                'Electricidad','Electrónica','Enfermería','Enología','Escribanía','Estadística','Farmacia','Filosofía','Finanzas',
                'Finanzas Internacionales','Fisioterapia','Fonoaudiologia','Fotografía','Gastronomía / Cocina','Geofísica','Geografía',
                'Geología / Geomensura / Topografía','Hidráulica','Histopatología','Historia','Hotelería','Intérprete','Kinesiología',
                'Laboratorio (Mecánica) Dental','Literatura','Maestro Mayor de Obras','Marketing / Comercialización','Matemáticas',
                'Mecánica / Metalúrgica','Medicina','Medio Ambiente','Mercadotecnia Internacional','Microbiología','Minería / Petróleo / Gas',
                'Música','Nutrición','Oceanografía','Odontología','Organización Industrial','Otra','Paisajismo','Periodismo','Perito Mercantil',
                'Procesos / Calidad Total','Programación','Psicología','Psicopedagogía','Publicidad','Química','Químico Farmacéutico',
                'Recursos Humanos / Relac. Ind.','Relaciones Internac.','Relaciones Públicas','Salud','Secretariado','Seguridad Industrial',
                'Seguros','Sociología','Tecnico','Tecnología Médica / Laboratorio','Tecnología de Alimentos','Tecnologías de la Información',
                'Telecomunicaciones','Terapia Ocupacional','Trabajo Social','Traducción','Transporte','Turismo','Ventas Internacionales/Exportación',
                'Veterinaria'
            ],
            items_tipos_estudio: ['Educación Inicial','Educación General Básica','Bachillerato','Terciario','Universitario',
            'Posgrado','Master','Doctorado','Otro'],
            items_estados_estudio: ['En curso','Graduado','Abandonado'],

            items_idiomas: ['Alemán','Chino Mandarín','Coreano','Español','Francés','Holandés','Inglés','Italiano','Japonés','Portugués'],
            items_nivel_oral_idiomas: ['Básico','Intermedio','Avanzado','Nativo'],
            items_nivel_escrito_idiomas: ['Básico','Intermedio','Avanzado','Nativo'],
            items_areas_informatica: ['Aplicaciones Laborales','Aplicaciones Móviles','Base de Datos','Hardware',
            'Lenguajes de Programación','Redes, conectividad e internet','SAP','Sistemas Operativos'],
            items_nivel_informatica: ['Básico','Intermedio','Avanzado','Experto'],
            items_areas: [],
            headers_experiencia_laboral: [
                { text: 'Puesto',align: 'start',sortable: false,value: 'puesto' },
                { text: 'Nombre empresa',align: 'start',sortable: false,value: 'nombre_empresa' },
                { text: 'Acciones', value: 'actions', sortable: false }
            ],
            headers_educacion: [
                { text: 'Título',align: 'start',sortable: false,value: 'titulo' },
                { text: 'Institución',align: 'start',sortable: false,value: 'institucion' },
                { text: 'Acciones', value: 'actions', sortable: false }
            ],
            headers_idiomas: [
                { text: 'Idioma',align: 'start',sortable: false,value: 'idioma' },
                { text: 'Nivel oral',align: 'start',sortable: false,value: 'nivel_oral' },
                { text: 'Nivel escrito',align: 'start',sortable: false,value: 'nivel_escrito' },
                { text: 'Acciones', value: 'actions', sortable: false }
            ],
            headers_informatica: [
                { text: 'Área',align: 'start',sortable: false,value: 'area' },
                { text: 'Conocimiento',align: 'start',sortable: false,value: 'conocimiento' },
                { text: 'Nivel',align: 'start',sortable: false,value: 'nivel' },
                { text: 'Acciones', value: 'actions', sortable: false }
            ],
            headers_otros: [
                { text: 'Título',align: 'start',sortable: false,value: 'titulo' },
                { text: 'Descripción',align: 'start',sortable: false,value: 'descripcion' },
                { text: 'Acciones', value: 'actions', sortable: false }
            ],
            editedIndexExperiencias: -1,
            editedItemExperiencias: {
                _id: null,
                area_puesto_id: '',
                hoja_de_vida_id: '',
                nombre_empresa: '',
                actividad_empresa: '',
                puesto: '',
                pais: '',
                fecha_desde: '',
                fecha_hasta: '',
                al_presente: 0,
                responsabilidades: '',
                persona_acargo: 0,
                delete: false
            },
            defaultItemExperiencias: {
                _id: null,
                area_puesto_id: '',
                hoja_de_vida_id: '',
                nombre_empresa: '',
                actividad_empresa: '',
                puesto: '',
                pais: '',
                fecha_desde: '',
                fecha_hasta: '',
                al_presente: 0,
                responsabilidades: '',
                persona_acargo: 0,
                delete: false
            },

            editedIndexEducacion: -1,
            editedItemEducacion: {
                _id: null,
                hoja_de_vida_id: '',
                pais: "",
                tipo_estudio: "",
                estado: "",
                fecha_desde: '',
                fecha_hasta: '',
                al_presente: 0,
                titulo: "",
                area_estudio: "",
                institucion: "",
                delete: false
            },
            defaultItemEducacion: {
                _id: null,
                hoja_de_vida_id: '',
                pais: "",
                tipo_estudio: "",
                estado: "",
                fecha_desde: '',
                fecha_hasta: '',
                al_presente: 0,
                titulo: "",
                area_estudio: "",
                institucion: "",
                delete: false
            },

            editedIndexIdioma: -1,
            editedItemIdioma: {
                _id: null,
                hoja_de_vida_id: '',
                idioma: "",
                nivel_oral: "",
                nivel_escrito: "",
                delete: false
            },
            defaultItemIdioma: {
                _id: null,
                hoja_de_vida_id: '',
                idioma: "",
                nivel_oral: "",
                nivel_escrito: "",
                delete: false
            },

            editedIndexInformatica: -1,
            editedItemInformatica: {
                _id: null,
                hoja_de_vida_id: '',
                area: "",
                conocimiento: "",
                nivel: "",
                delete: false
            },
            defaultItemInformatica: {
                _id: null,
                hoja_de_vida_id: '',
                area: "",
                conocimiento: "",
                nivel: "",
                delete: false
            },


            editedIndexOtros: -1,
            editedItemOtros: {
                _id: null,
                hoja_de_vida_id: '',
                titulo: "",
                descripcion: "",
                delete: false
            },
            defaultItemOtros: {
                _id: null,
                hoja_de_vida_id: '',
                titulo: "",
                descripcion: "",
                delete: false
            },

            data: {
                _id: null,
                datospersonales: {
                    _id: null,
                    nombre: '',
                    apellidos: '',
                    genero: 'Femenino',
                    estado_civil: 'Soltero/a',
                    nacionalidad: '',
                    fecha_nacimiento: '',
                    tipo_documento: 'Documento de identidad',
                    numero_documento: '',
                    poseo_licencia: 0,
                    poseo_discapacidad: 0,
                    poseo_movilidad: 0,
                },
                datoscontacto: {
                    _id: null,
                    celular_numero: '',
                    telefono_numero: '',
                    email: '',
                    pais: 'Ecuador',
                    provincia_id: '',
                    localidad_id: '',
                    calle: ''
                },
                preferencia_laboral: {
                    _id: null,
                    salario_bruto_pretendido: 0,
                    objetivo_laboral: ''
                },
                experiencias_laborales: [],
                educaciones: [],
                idiomas: [],
                informaticas: [],
                otros_conocimientos: [],
                sin_experiencia: 0,
                filepath: '',
                image: null,
                delete: false
            },
            menu: false,
            menuDesdeExperiencias: false,
            menuHastaExperiencias: false,
            menuDesdeEducacion: false,
            menuHastaEducacion: false,
            rules: {
                file : {
                    rule: [
                        value => !value || value.size < 2000000 || 'El tamaño del logo debería ser menor a 2 MB!',
                    ]
                },
                datoscontacto : {
                    celular_numero: {
                        rule: [
                            v => !!v || 'Teléfono de celular es requerido',
                            v => v.length <= this.rules.datoscontacto.celular_numero.counter || `El teléfono celular debe ser menor o igual a ${this.rules.datoscontacto.celular_numero.counter} caracteres`,
                        ],
                        counter: 30
                    },
                    telefono_numero: {
                        rule: [
                            v => !!v || 'Teléfono fijo es requerido',
                            v => v.length <= this.rules.datoscontacto.telefono_numero.counter || `El teléfono fijo debe ser menor o igual a ${this.rules.datoscontacto.telefono_numero.counter} caracteres`,
                        ],
                        counter: 30
                    },
                    email: {
                        rule: [
                        v => !!v || 'Email es requerido',
                        v => {
                            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            return pattern.test(v) || 'Email inválido.'
                        }
                    ]
                    },
                    calle: {
                        rule: [
                            v => !!v || 'Calle es requerida',
                            v => v.length <= this.rules.datoscontacto.calle.counter || `Calle debe ser menor o igual a ${this.rules.datoscontacto.calle.counter} caracteres`,
                        ],
                        counter: 100
                    }
                },
                datospersonales: {
                    nombre: {
                        rule: [
                            v => !!v || 'Nombre es requerido',
                            v => v.length <= this.rules.datospersonales.nombre.counter || `El nombre debe ser menor o igual a ${this.rules.datospersonales.nombre.counter} caracteres`,
                        ],
                        counter: 30
                    },
                    apellidos: {
                        rule: [
                            v => !!v || 'Apellido es requerido',
                            v => v.length <= this.rules.datospersonales.apellidos.counter || `El apellido debe ser menor o igual a ${this.rules.datospersonales.apellidos.counter} caracteres`,
                        ],
                        counter: 30
                    },
                    nacionalidad: {
                        rule: [
                            v => !!v || 'Nacionalidad es requerido',
                            v => v.length <= this.rules.datospersonales.nacionalidad.counter || `La nacionalidad debe ser menor o igual a ${this.rules.datospersonales.nacionalidad.counter} caracteres`,
                        ],
                        counter: 40
                    },
                    numero_documento: {
                        rule: [
                            v => !!v || 'Número de documento es requerido',
                            v => v.length <= this.rules.datospersonales.numero_documento.counter || `El número de documento debe ser menor o igual a ${this.rules.datospersonales.numero_documento.counter} caracteres`,
                        ],
                        counter: 30
                    },
                },
                preferencia_laboral: {
                    
                    objetivo_laboral: {
                        rule: [
                            v => !!v || 'Objetivo laboral debe ser requerido',
                            v => v.length <= this.rules.preferencia_laboral.objetivo_laboral.counter || `El objetivo laboral debe ser menor o igual a ${this.rules.preferencia_laboral.objetivo_laboral.counter} caracteres`,
                        ],
                        counter: 200
                    }
                },
                experiencias_laborales: {
                    nombre_empresa: {
                        rule: [
                            v => !!v || 'Nombre de empresa es requerido',
                            v => v.length <= this.rules.experiencias_laborales.nombre_empresa.counter || `El nombre de empresa debe ser menor o igual a ${this.rules.experiencias_laborales.nombre_empresa.counter} caracteres`,
                        ],
                        counter: 30
                    },
                    puesto: {
                        rule: [
                            v => !!v || 'Puesto es requerido',
                            v => v.length <= this.rules.experiencias_laborales.puesto.counter || `El puesto debe ser menor o igual a ${this.rules.experiencias_laborales.puesto.counter} caracteres`,
                        ],
                        counter: 30
                    },
                    pais: {
                        rule: [
                            v => !!v || 'País es requerido',
                            v => v.length <= this.rules.experiencias_laborales.pais.counter || `El país debe ser menor o igual a ${this.rules.experiencias_laborales.pais.counter} caracteres`,
                        ],
                        counter: 30
                    },
                    responsabilidades: {
                        rule: [
                            v => !!v || 'Las responsabilidades son requeridas',
                            v => v.length <= this.rules.experiencias_laborales.responsabilidades.counter || `Las responsabilidades debe ser menor o igual a ${this.rules.experiencias_laborales.responsabilidades.counter} caracteres`,
                        ],
                        counter: 300
                    }
                },
                educacion: {
                    institucion: {
                        rule: [
                            v => !!v || 'Institución es requerido',
                            v => v.length <= this.rules.educacion.institucion.counter || `La instutución debe ser menor o igual a ${this.rules.educacion.institucion.counter} caracteres`,
                        ],
                        counter: 100
                    },
                    titulo: {
                        rule: [
                            v => !!v || 'Título es requerido',
                            v => v.length <= this.rules.educacion.titulo.counter || `El título debe ser menor o igual a ${this.rules.educacion.titulo.counter} caracteres`,
                        ],
                        counter: 100
                    },
                    pais: {
                        rule: [
                            v => !!v || 'País es requerido',
                            v => v.length <= this.rules.educacion.pais.counter || `El país debe ser menor o igual a ${this.rules.educacion.pais.counter} caracteres`,
                        ],
                        counter: 30
                    }
                },
                informatica: {
                    conocimiento: {
                        rule: [
                            v => !!v || 'Conocimiento es requerido',
                            v => v.length <= this.rules.informatica.conocimiento.counter || `El conocimiento debe ser menor o igual a ${this.rules.informatica.conocimiento.counter} caracteres`,
                        ],
                        counter: 40
                    },
                },
                otros: {
                    titulo: {
                        rule: [
                            v => !!v || 'Título es requerido',
                            v => v.length <= this.rules.otros.titulo.counter || `El título debe ser menor o igual a ${this.rules.otros.titulo.counter} caracteres`,
                        ],
                        counter: 40
                    },
                    descripcion: {
                        rule: [
                            v => !!v || 'Descripción es requerida',
                            v => v.length <= this.rules.otros.descripcion.counter || `La descripción debe ser menor o igual a ${this.rules.otros.descripcion.counter} caracteres`,
                        ],
                        counter: 100
                    },
                }
            },
            scene: {
                accessToken: 'pk.eyJ1IjoibGF0YWJlcm5hIiwiYSI6ImNqemJmYTJlNDAwM2czZG8zaWg5ODEydnUifQ.QG97tATPIyf3zOA9Cn8S9w',
                zoom: 13,
                pitch: 20,
                bearing: 0,
                transitionMode:'fly',
                mapStyle: 'mapbox://styles/mapbox/streets-v11'
            },
            map: null,
            marker: null
        }
    },
    computed: {
        ...mapGetters(['system','baseurl']),
        method () {
            return this.$route.params.id ? 'put' :'post'
        },

        methodDatosPersonales () {
            return this.data.datospersonales._id ? 'put' : 'post'
        },
        urlDatosPersonales() {
            return !this.data.datospersonales._id ? '/datos-personales' : `/datos-personales/${this.data.datospersonales._id}`
        },

        dataDatosPersonales() {
           return {
                nombre: this.data.datospersonales.nombre,
                apellidos: this.data.datospersonales.apellidos,
                genero: this.data.datospersonales.genero,
                estado_civil: this.data.datospersonales.estado_civil,
                nacionalidad: this.data.datospersonales.nacionalidad,
                fecha_nacimiento: this.data.datospersonales.fecha_nacimiento,
                tipo_documento: this.data.datospersonales.tipo_documento,
                numero_documento: this.data.datospersonales.numero_documento,
                poseo_licencia: this.data.datospersonales.poseo_licencia,
                poseo_discapacidad: this.data.datospersonales.poseo_discapacidad,
                poseo_movilidad: this.data.datospersonales.poseo_movilidad,
                image: this.data.image,
                filepath: this.data.filepath,
                _id_hoja_de_vida: this.data._id
           }
            
        },

        methodDatosContacto () {
            return this.data.datoscontacto._id ? 'put' : 'post'
        },
        urlDatosContacto() {
            return !this.data.datoscontacto._id ? '/datos-contacto' : `/datos-contacto/${this.data.datoscontacto._id}`
        },

        dataDatosContacto() {
           return {
                celular_numero: this.data.datoscontacto.celular_numero,
                telefono_numero: this.data.datoscontacto.telefono_numero,
                email:  this.data.datoscontacto.email,
                calle: this.data.datoscontacto.calle,
                pais: this.data.datoscontacto.pais,
                localidad_id: this.data.datoscontacto.localidad_id,
                provincia_id: this.data.datoscontacto.provincia_id,
                _id_hoja_de_vida: this.data._id
           }
            
        },


        methodPreferenciaLaboral () {
            return this.data.preferencia_laboral._id ? 'put' : 'post'
        },
        urlPreferenciaLaboral() {
            return !this.data.preferencia_laboral._id ? '/preferencia-laboral' : `/preferencia-laboral/${this.data.preferencia_laboral._id}`
        },

        dataPreferenciaLaboral() {
           return {
                salario_bruto_pretendido: this.data.preferencia_laboral.salario_bruto_pretendido,
                objetivo_laboral: this.data.preferencia_laboral.objetivo_laboral,
                _id_hoja_de_vida: this.data._id
           }
            
        },

        dataHojaDeVida() {
            return {
                datoscontacto: this.data.datoscontacto,
                datospersonales: this.data.datospersonales,
                preferencia_laboral: this.data.preferencia_laboral,
                image: this.data.image,
                sin_experiencia: this.data.sin_experiencia,
                contactos: this.data.contactos,
                verificado: this.data.verificado,
                presencia: this.data.presencia,
                delete: this.data.delete
            }
        },
        formTitleExperiencias () {
            return this.editedIndexExperiencias === -1 ? 'Agregar Educación' : 'Editar Educación'
        },
        formTitleEducacion () {
            return this.editedIndexEducacion === -1 ? 'Agregar Educación' : 'Editar Educación'
        },
        formTitleIdiomas () {
            return this.editedIndexIdioma === -1 ? 'Agregar Idioma' : 'Editar Idioma'
        },
        formTitleInformatica () {
            return this.editedIndexInformatica === -1 ? 'Agregar Informática' : 'Editar Informática'
        },
        formTitleOtros () {
            return this.editedIndexOtros === -1 ? 'Agregar Otros' : 'Editar Otros'
        },
        readonly () {
            let readonly = false
            if (this.$route.params.id){
                if (this.$route.params.action == 'ver')
                    readonly = true
            }
            return readonly
        },
        url () {
            return `/hojadevida/${this.data._id}`;
        },
        urlExperiencia() {
            return this.editedItemExperiencias._id === null ? '/experiencias-laborales' : `/experiencias-laborales/${this.editedItemExperiencias._id}`
        },
        methodExperiencia() {
            return this.editedItemExperiencias._id === null ? 'post' : 'put'
        },
        urlEducacion() {
            return this.editedItemEducacion._id === null ? '/educacion' : `/educacion/${this.editedItemEducacion._id}`
        },
        methodEducacion() {
            return this.editedItemEducacion._id === null ? 'post' : 'put'
        },
        urlIdiomas() {
            return this.editedItemIdioma._id === null ? '/idiomas' : `/idiomas/${this.editedItemIdioma._id}`
        },
        methodIdiomas() {
            return this.editedItemIdioma._id === null ? 'post' : 'put'
        },
        urlInformatica() {
            return this.editedItemInformatica._id === null ? '/informatica' : `/informatica/${this.editedItemInformatica._id}`
        },
        methodInformatica() {
            return this.editedItemInformatica._id === null ? 'post' : 'put'
        },

        urlOtros() {
            return this.editedItemOtros._id === null ? '/otros-conocimientos' : `/otros-conocimientos/${this.editedItemOtros._id}`
        },
        methodOtros() {
            return this.editedItemOtros._id === null ? 'post' : 'put'
        },
        titulo () {
            let titulo = "Crear Hoja de Vida"
            if (this.$route.params.id){
                if (this.$route.params.action == 'editar')
                    titulo = "Editar Hoja de Vida"
            }
            return titulo
        },
        filepathfull () {
            let path = this.data.filepath
            if (!this.local && path){
                const url = this.baseurl.substr(0,this.baseurl.length-3)
                path = url+this.data.filepath
            }
            return path
        }
    },
    watch: {
        optionsexperiencias: {
            handler() {
                this.readExperienciasLaboralesFromAPI();
            },
        }, 
        optionseducacion: {
            handler() {
                this.readEducacionFromAPI();
            },
        }, 
        optionsidiomas: {
            handler() {
                this.readIdiomasFromAPI();
            },
        }, 
        optionsinformatica: {
            handler() {
                this.readIdiomasFromAPI();
            },
        }, 
        optionsotros: {
            handler() {
                this.readOtrosFromAPI();
            },
        }, 
        dialogExperiencias (val) {
            val || this.closeExperiencias()
        },
        dialogDeleteExperiencias (val) {
            val || this.closeDeleteExperiencias()
        },
        dialogEducacion (val) {
            val || this.closeEducacion()
        },
        dialogDeleteEducacion (val) {
            val || this.closeDeleteEducacion()
        },
        dialogIdiomas (val) {
            val || this.closeIdiomas()
        },
        dialogDeleteIdiomas (val) {
            val || this.closeDeleteIdiomas()
        },
        dialogInformatica (val) {
            val || this.closeInformatica()
        },
        dialogDeleteInformatica (val) {
            val || this.closeDeleteInformatica()
        },
        dialogOtros (val) {
            val || this.closeOtros()
        },
        dialogDeleteOtros (val) {
            val || this.closeDeleteOtros()
        },
        'data.image':function(val) {
            if (typeof val == 'object'){
                this.local = true
                this.data.filepath = URL.createObjectURL(val)
            }
        },
        'data.datoscontacto.provincia_id': async function(val) {
            //this.data.datoscontacto.localidad_id = '';
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            if (userLogged && this.data.datoscontacto.provincia_id ) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers)
                let response = await api.call_respuesta_directa(`/localidades/provincia/${val}/select/all`) 
                response = response.data ? response.data.data : this.items_localidades
                this.items_localidades = response
            }
        }
    },
    components: {
        TituloPrincipalCx,
        FormCx,
        SubmitCx,
        //MapboxMap
    },
    async created() {
        
        await this.init()
        //this.$store.dispatch('setItemSelected','empresas_crear')
    },
    methods: {
        async init() {
            this.local = false
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers)
                let response = await api.call_respuesta_directa(`/hojadevida`) 
                response = response.data ? response.data.data : this.data;
                //response._id
                delete response.created_at
                delete response.updated_at
                
                this.data = response
                this.editedItemExperiencias.hoja_de_vida_id = response._id;
                this.defaultItemExperiencias.hoja_de_vida_id = response._id;
                this.editedItemEducacion.hoja_de_vida_id = response._id;
                this.defaultItemEducacion.hoja_de_vida_id = response._id;
                this.defaultItemIdioma.hoja_de_vida_id = response._id;
                this.editedItemIdioma.hoja_de_vida_id = response._id;
                this.defaultItemInformatica.hoja_de_vida_id = response._id;
                this.editedItemInformatica.hoja_de_vida_id = response._id;
                this.defaultItemOtros.hoja_de_vida_id = response._id;
                this.editedItemOtros.hoja_de_vida_id = response._id;
                this.processImage()
                
                response = await api.call_respuesta_directa(`/provincias/select/all`) 
                response = response.data ? response.data.data : this.items_provincias
                this.items_provincias = response

                response = await api.call_respuesta_directa(`/areas-puestos/select/all`) 
                response = response.data ? response.data.data : this.items_areas
                this.items_areas = response

                this.readExperienciasLaboralesFromAPI()
                this.readEducacionFromAPI()
                this.readIdiomasFromAPI()
                this.readInformaticaFromAPI()
                this.readOtrosFromAPI()

            }
        },
        async readExperienciasLaboralesFromAPI () {
            this.loadingExperiencias = true;
            const { page, itemsPerPage } = this.optionsexperiencias;
            
            let pageNumber = page - 1;
            let params = {
                size: itemsPerPage,
                page: pageNumber,
                hoja_de_vida_id: parseInt(this.data._id)
            }
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            let experiencias = []
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers,{},params)
                let response = await api.call_respuesta_directa('/experiencias-laborales') 
                experiencias = response.data ? response.data.data : [];
                this.loadingExperiencias = false;
                this.totalExperiencias = response.data.totalExperiencias;
                this.numberOfPagesExperiencias = response.data.totalPages;
            }
            this.data.experiencias_laborales = experiencias
        },
        async readEducacionFromAPI () {
            this.loadingEducacion = true;
            const { page, itemsPerPage } = this.optionseducacion;
            
            let pageNumber = page - 1;
            let params = {
                size: itemsPerPage,
                page: pageNumber,
                hoja_de_vida_id: parseInt(this.data._id)
            }
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            let educaciones = []
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers,{},params)
                let response = await api.call_respuesta_directa('/educacion') 
                educaciones = response.data ? response.data.data : [];
                this.loadingEducacion = false;
                this.totalEducacion = response.data.totalEducacion;
                this.numberOfPagesEducacion = response.data.totalPages;
            }
            this.data.educaciones = educaciones
        },
        async readIdiomasFromAPI () {
            this.loadingIdiomas = true;
            const { page, itemsPerPage } = this.optionsidiomas;
            
            let pageNumber = page - 1;
            let params = {
                size: itemsPerPage,
                page: pageNumber,
                hoja_de_vida_id: parseInt(this.data._id)
            }
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            let idiomas = []
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers,{},params)
                let response = await api.call_respuesta_directa('/idiomas') 
                idiomas = response.data ? response.data.data : [];
                this.loadingIdiomas = false;
                this.totalIdiomas = response.data.totalIdiomas;
                this.numberOfPagesIdiomas = response.data.totalPages;
            }
            this.data.idiomas = idiomas
        },
        async readInformaticaFromAPI () {
            this.loadingInformatica = true;
            const { page, itemsPerPage } = this.optionsinformatica;
            
            let pageNumber = page - 1;
            let params = {
                size: itemsPerPage,
                page: pageNumber,
                hoja_de_vida_id: parseInt(this.data._id)
            }
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            let informaticas = []
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers,{},params)
                let response = await api.call_respuesta_directa('/informatica') 
                informaticas = response.data ? response.data.data : [];
                this.loadingInformatica = false;
                this.totalInformatica = response.data.totalInformatica;
                this.numberOfPagesInformatica = response.data.totalPages;
            }
            this.data.informaticas = informaticas
        },
        async readOtrosFromAPI () {
            this.loadingOtros = true;
            const { page, itemsPerPage } = this.optionsotros;
            
            let pageNumber = page - 1;
            let params = {
                size: itemsPerPage,
                page: pageNumber,
                hoja_de_vida_id: parseInt(this.data._id)
            }
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            let otros = []
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers,{},params)
                let response = await api.call_respuesta_directa('/otros-conocimientos') 
                otros = response.data ? response.data.data : [];
                this.loadingOtros = false;
                this.totalOtros = response.data.totalOtros;
                this.numberOfPagesOtros = response.data.totalPages;
            }
            this.data.otros_conocimientos = otros
        },
        save (date) {
            this.$refs.menu.save(date)
        },
        saveFechaDesdeExperiencias (date) {
            this.$refs.menuDesdeExperiencias.save(date)
        },
        saveFechaHastaExperiencias (date) {
            this.$refs.menuHastaExperiencias.save(date)
        },

        saveFechaDesdeEducacion (date) {
            this.$refs.menuDesdeEducacion.save(date)
        },
        saveFechaHastaEducacion (date) {
            this.$refs.menuHastaEducacion.save(date)
        },
        
        processImage () {
            if (this.filepathfull){
                this.exist_logo = this.filepathfull ? true : false
            }
        },
        successExperiencia (response) {
            
            this.$notify(
                {
                    group: "bottom",
                    title: "Experiencia",
                    text: response.data ? response.data.message : "Experiencia guardada correctamente.",
                    type: 'info'
                },
                4000
            );
            this.readExperienciasLaboralesFromAPI();
            this.closeExperiencias();
        },
        errorExperiencia () {
            this.$notify(
                {
                    group: "bottom",
                    title: "Error",
                    text: "Ocurrió un inconveniente",
                    type: "error"
                },
                4000
            );
            
        },
        successEducacion (response) {
            
            this.$notify(
                {
                    group: "bottom",
                    title: "Educación",
                    text: response.data ? response.data.message : "Educación guardada correctamente.",
                    type: 'info'
                },
                4000
            );
            this.readEducacionFromAPI();
            this.closeEducacion();
        },
        errorEducacion () {
            this.$notify(
                {
                    group: "bottom",
                    title: "Error",
                    text: "Ocurrió un inconveniente",
                    type: "error"
                },
                4000
            );
            
        },
        successIdiomas (response) {
            
            this.$notify(
                {
                    group: "bottom",
                    title: "Idiomas",
                    text: response.data ? response.data.message : "Idioma guardado correctamente.",
                    type: 'info'
                },
                4000
            );
            this.readIdiomasFromAPI();
            this.closeIdiomas();
        },
        errorIdiomas () {
            this.$notify(
                {
                    group: "bottom",
                    title: "Error",
                    text: "Ocurrió un inconveniente",
                    type: "error"
                },
                4000
            );
            
        },
        successInformatica (response) {
            
            this.$notify(
                {
                    group: "bottom",
                    title: "Informática",
                    text: response.data ? response.data.message : "Informática guardado correctamente.",
                    type: 'info'
                },
                4000
            );
            this.readInformaticaFromAPI();
            this.closeInformatica();
        },
        errorInformatica () {
            this.$notify(
                {
                    group: "bottom",
                    title: "Error",
                    text: "Ocurrió un inconveniente",
                    type: "error"
                },
                4000
            );
            
        },
        successOtros (response) {
            
            this.$notify(
                {
                    group: "bottom",
                    title: "Otros conocimientos",
                    text: response.data ? response.data.message : "Otros conocimientos guardados correctamente.",
                    type: 'info'
                },
                4000
            );
            this.readOtrosFromAPI();
            this.closeOtros();
        },
        errorOtros () {
            this.$notify(
                {
                    group: "bottom",
                    title: "Error",
                    text: "Ocurrió un inconveniente",
                    type: "error"
                },
                4000
            );
            
        },
        async success (response) {
            this.$notify(
                {
                    group: "bottom",
                    title: "Datos guardados",
                    text: response.data ? response.data.message : "Guardado correctamente.",
                    type: 'success'
                },
                4000
            );
            await this.init()
        },
        error () {
            this.$notify(
                {
                    group: "bottom",
                    title: "Error",
                    text: "Ocurrió un inconveniente",
                    type: "error"
                },
                4000
            );
            
        },

        closeExperiencias () {
            this.dialogExperiencias = false
            this.$nextTick(() => {
            this.editedItemExperiencias = Object.assign({}, this.defaultItemExperiencias)
            this.editedIndexExperiencias = -1
            })
        },

        closeDeleteExperiencias () {
            this.dialogDeleteExperiencias = false
            this.$nextTick(() => {
            this.editedItemExperiencias = Object.assign({}, this.defaultItemExperiencias)
            this.editedIndexExperiencias = -1
            })
        },

        saveExperiencias () {
            if (this.editedIndexExperiencias > -1) {
                Object.assign(this.data.experiencias_laborales[this.editedIndexExperiencias], this.editedItemExperiencias)
            } else {

                this.data.experiencias_laborales.push(this.editedItemExperiencias)
            }
            this.closeExperiencias()
        },

        editItemExperiencias (item) {
            this.editedIndexExperiencias = this.data.experiencias_laborales.indexOf(item)
            this.editedItemExperiencias = Object.assign({}, item)
            this.dialogExperiencias = true
        },

        deleteItemExperiencias (item) {
            this.editedIndexExperiencias = this.data.experiencias_laborales.indexOf(item)
            this.editedItemExperiencias = Object.assign({}, item)
            this.dialogDeleteExperiencias = true
        },

        deleteItemConfirmExperiencias () {
            this.deleteApiExperiencias(this.editedItemExperiencias._id)
            /*this.data.experiencias_laborales.splice(this.editedIndexExperiencias, 1)
            this.closeDeleteExperiencias()*/
        },

        async deleteApiExperiencias(id_experiencia) {
            let response = false;
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null;
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.$store.getters.baseurl,'delete',headers)
                response = await api.call_respuesta_directa(`/experiencias-laborales/${id_experiencia}`) 
            }
            if (response && response.status == 200) {
                this.data.experiencias_laborales.splice(this.editedIndexExperiencias, 1)
                this.closeDeleteExperiencias()
                this.readExperienciasLaboralesFromAPI();
            }else{
                this.$notify(
                    {
                        group: "bottom",
                        title: "Error",
                        text: "No se pudo eliminar experiencia",
                        type: "error"
                    },
                    4000
                );
                this.closeDeleteExperiencias()
            }

        },


        closeEducacion () {
            this.dialogEducacion = false
            this.$nextTick(() => {
            this.editedItemEducacion = Object.assign({}, this.defaultItemEducacion)
            this.editedIndexEducacion = -1
            })
        },

        closeDeleteEducacion () {
            this.dialogDeleteEducacion = false
            this.$nextTick(() => {
            this.editedItemEducacion = Object.assign({}, this.defaultItemEducacion)
            this.editedIndexEducacion = -1
            })
        },

        

        editItemEducacion (item) {
            this.editedIndexEducacion = this.data.educaciones.indexOf(item)
            this.editedItemEducacion = Object.assign({}, item)
            this.dialogEducacion = true
        },

        deleteItemEducacion (item) {
            this.editedIndexEducacion = this.data.educaciones.indexOf(item)
            this.editedItemEducacion = Object.assign({}, item)
            this.dialogDeleteEducacion = true
        },

        deleteItemConfirmEducacion () {
            this.deleteApiEducacion(this.editedItemEducacion._id)
            /*this.data.experiencias_laborales.splice(this.editedIndexExperiencias, 1)
            this.closeDeleteExperiencias()*/
        },

        async deleteApiEducacion(id_educacion) {
            let response = false;
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null;
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.$store.getters.baseurl,'delete',headers)
                response = await api.call_respuesta_directa(`/educacion/${id_educacion}`) 
            }
            if (response && response.status == 200) {
                this.data.educaciones.splice(this.editedIndexEducacion, 1)
                this.closeDeleteEducacion()
                this.readEducacionFromAPI();
            }else{
                this.$notify(
                    {
                        group: "bottom",
                        title: "Error",
                        text: "No se pudo eliminar educación",
                        type: "error"
                    },
                    4000
                );
                this.closeDeleteEducacion()
            }

        },




        closeIdiomas () {
            this.dialogIdiomas = false
            this.$nextTick(() => {
            this.editedItemIdioma = Object.assign({}, this.defaultItemIdioma)
            this.editedIndexIdioma = -1
            })
        },

        closeDeleteIdiomas () {
            this.dialogDeleteIdiomas = false
            this.$nextTick(() => {
            this.editedItemIdioma = Object.assign({}, this.defaultItemIdioma)
            this.editedIndexIdioma = -1
            })
        },

       

        editItemIdiomas (item) {
            this.editedIndexIdioma = this.data.idiomas.indexOf(item)
            this.editedItemIdioma = Object.assign({}, item)
            this.dialogIdiomas = true
        },

        deleteItemIdiomas (item) {
            this.editedIndexIdioma = this.data.idiomas.indexOf(item)
            this.editedItemIdioma = Object.assign({}, item)
            this.dialogDeleteIdiomas = true
        },

        deleteItemConfirmIdiomas () {
            this.deleteApiIdiomas(this.editedItemIdioma._id)
            /*this.data.experiencias_laborales.splice(this.editedIndexExperiencias, 1)
            this.closeDeleteExperiencias()*/
        },

        async deleteApiIdiomas(id_idioma) {
            let response = false;
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null;
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.$store.getters.baseurl,'delete',headers)
                response = await api.call_respuesta_directa(`/idiomas/${id_idioma}`) 
            }
            if (response && response.status == 200) {
                this.data.idiomas.splice(this.editedIndexIdioma, 1)
                this.closeDeleteIdiomas()
                this.readIdiomasFromAPI();
            }else{
                this.$notify(
                    {
                        group: "bottom",
                        title: "Error",
                        text: "No se pudo eliminar idioma",
                        type: "error"
                    },
                    4000
                );
                this.closeDeleteIdiomas()
            }

        },












        closeInformatica () {
            this.dialogInformatica = false
            this.$nextTick(() => {
            this.editedItemInformatica = Object.assign({}, this.defaultItemInformatica)
            this.editedIndexInformatica = -1
            })
        },

        closeDeleteInformatica () {
            this.dialogDeleteInformatica = false
            this.$nextTick(() => {
            this.editedItemInformatica = Object.assign({}, this.defaultItemInformatica)
            this.editedIndexInformatica = -1
            })
        },

       

        editItemInformatica (item) {
            this.editedIndexInformatica = this.data.informaticas.indexOf(item)
            this.editedItemInformatica = Object.assign({}, item)
            this.dialogInformatica = true
        },

        deleteItemInformatica (item) {
            this.editedIndexInformatica = this.data.informaticas.indexOf(item)
            this.editedItemInformatica = Object.assign({}, item)
            this.dialogDeleteInformatica = true
        },

        deleteItemConfirmInformatica () {
            this.deleteApiInformatica(this.editedItemInformatica._id)
            /*this.data.experiencias_laborales.splice(this.editedIndexExperiencias, 1)
            this.closeDeleteExperiencias()*/
        },

        async deleteApiInformatica(id_informatica) {
            let response = false;
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null;
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.$store.getters.baseurl,'delete',headers)
                response = await api.call_respuesta_directa(`/informatica/${id_informatica}`) 
            }
            if (response && response.status == 200) {
                this.data.informaticas.splice(this.editedIndexInformatica, 1)
                this.closeDeleteInformatica()
                this.readInformaticaFromAPI();
            }else{
                this.$notify(
                    {
                        group: "bottom",
                        title: "Error",
                        text: "No se pudo eliminar informática",
                        type: "error"
                    },
                    4000
                );
                this.closeDeleteInformatica()
            }

        },









        closeOtros () {
            this.dialogOtros = false
            this.$nextTick(() => {
            this.editedItemOtros = Object.assign({}, this.defaultItemOtros)
            this.editedIndexOtros = -1
            })
        },

        closeDeleteOtros () {
            this.dialogDeleteOtros = false
            this.$nextTick(() => {
            this.editedItemOtros = Object.assign({}, this.defaultItemOtros)
            this.editedIndexOtros = -1
            })
        },

       

        editItemOtros (item) {
            this.editedIndexOtros = this.data.otros_conocimientos.indexOf(item)
            this.editedItemOtros = Object.assign({}, item)
            this.dialogOtros = true
        },

        deleteItemOtros (item) {
            this.editedIndexOtros = this.data.otros_conocimientos.indexOf(item)
            this.editedItemOtros = Object.assign({}, item)
            this.dialogDeleteOtros = true
        },

        deleteItemConfirmOtros () {
            this.deleteApiOtros(this.editedItemOtros._id)
            /*this.data.experiencias_laborales.splice(this.editedIndexExperiencias, 1)
            this.closeDeleteExperiencias()*/
        },

        async deleteApiOtros(id_otros) {
            let response = false;
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null;
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.$store.getters.baseurl,'delete',headers)
                response = await api.call_respuesta_directa(`/otros-conocimientos/${id_otros}`) 
            }
            if (response && response.status == 200) {
                this.data.otros_conocimientos.splice(this.editedIndexOtros, 1)
                this.closeDeleteOtros()
                this.readOtrosFromAPI();
            }else{
                this.$notify(
                    {
                        group: "bottom",
                        title: "Error",
                        text: "No se pudo eliminar",
                        type: "error"
                    },
                    4000
                );
                this.closeDeleteOtros()
            }

        },
        
    }
}
</script>

<style>


</style>