<template>
    <v-row>
        <v-col cols="12" class="py-0">
            <titulo-principal-cx :titulo="titulo" />
        </v-col>
        <v-col cols="12" md="3" class="p-6">
            <router-link :to="{ name: 'AdministrarPublicaciones'}">
                Volver al listado
            </router-link>
            <hr/>
            <v-row> 
                <v-col cols="3" md="12" class="p-4">
                    <br/>
                    <span>Filtros:</span>
                </v-col>
                <v-col cols="12" class="pl-4 pt-0">
                    <v-select
                        :items="items_genero"
                        @change="readDataFromAPI()"
                        label="Género"
                        v-model="genero"
                    ></v-select>
                    <v-text-field
                        v-model="edad_desde"
                        @keydown="fetchEntriesDebounced()"
                        @change="fetchEntriesDebounced()"
                        type="number"
                        label="Edad desde"
                    ></v-text-field>
                    <v-text-field
                        v-model="edad_hasta"
                        @keydown="fetchEntriesDebounced()"
                        @change="fetchEntriesDebounced()"
                        type="number"
                        label="Edad hasta"
                    ></v-text-field>
                    <v-text-field
                        v-model="salario_pretendido_hasta"
                        @keydown="fetchEntriesDebounced()"
                        label="Salario pretendido (hasta)"
                    ></v-text-field>
                    
                    <v-select
                        :items="items_nivel_educativo"
                        @change="readDataFromAPI()"
                        label="Nivel educativo"
                        v-model="nivel_educativo"
                    ></v-select>
                    
                    <v-select
                        :items="items_presencia"
                        @change="readDataFromAPI()"
                        label="Presencia"
                        v-model="valoracion_presencia"
                    ></v-select>
                    <v-select
                        :items="items_contactos"
                        @change="readDataFromAPI()"
                        label="Contactos"
                        v-model="valoracion_contactos"
                    ></v-select>
                    <v-select
                        :items="items_verificado"
                        @change="readDataFromAPI()"
                        label="Verificado"
                        v-model="valoracion_verificado"
                    ></v-select>
                </v-col>
            </v-row>

        </v-col>
        <v-col cols="12" md="9" class="py-0">
             <div class="text-center pt-2">
                <v-pagination
                    v-model="page"
                    :length="numberOfPages"
                ></v-pagination>
            </div>

            <v-dialog v-model="dialogLink" max-width="550px">
                <v-card>
                    <v-card-title class="headline">CV</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-progress-circular
                        v-if="linkpdf === ''"
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                    <a v-if="linkpdf !== ''" :href="linkpdf" target="_blank">Ver PDF</a>
                    
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
            <v-data-table
                no-data-text="Postulantes no disponibles"
                no-results-text="Resultados no encontrados"
                :page="page"
                hide-default-footer
                :pageCount="numberOfPages"
                :headers="headers"
                :items="AdministrarPublicaciones"
                :options.sync="options"
                :server-items-length="totalAdministrarPublicaciones"
                :loading="loading"
                :search="search"
                sort-by="nombre"
                class="elevation-1"
            >
                <template v-slot:top>
                    <v-text-field
                        v-model="search"
                        label="Buscar por: nombre, apellido, documento, email, título, institución"
                        class="mx-4"
                    ></v-text-field>
                    <v-dialog v-model="dialogCalificacion" transition="dialog-bottom-transition" max-width="600">
                    <v-card class="p-4">
                        <v-card-title>Calificar a: {{ nombreCalificar }} </v-card-title>
                        <v-spacer></v-spacer>
                            <form-cx action="#" method="put" :url="urlCalificacion" :data="dataCalificacion" @success="success" @error="error">
                                <v-row>
                                    <v-col cols="12">
                                        <v-radio-group v-model="editedItemCalificacion.presencia" row class="p-2">
                                            <v-radio
                                                label="Mono"
                                                :value="uno"
                                            ></v-radio>
                                            <v-radio
                                                label="Dragón"
                                                :value="dos"
                                            ></v-radio>
                                            <v-radio
                                                label="Delfín"
                                                :value="tres"
                                            ></v-radio>
                                        </v-radio-group>
                                        <v-radio-group v-model="editedItemCalificacion.contactos" row class="p-2">
                                            <v-radio
                                                label="Dorado"
                                                :value="tres"
                                            ></v-radio>
                                            <v-radio
                                                label="Negro"
                                                :value="dos"
                                            ></v-radio>
                                            <v-radio
                                                label="Check"
                                                :value="uno"
                                            ></v-radio>
                                        </v-radio-group>
                                        <v-radio-group v-model="editedItemCalificacion.verificado" row class="p-2">
                                            <v-radio
                                                label="Trebol"
                                                :value="uno"
                                            ></v-radio>
                                            <v-radio
                                                label="Pic"
                                                :value="dos"
                                            ></v-radio>
                                            <v-radio
                                                label="Corazon"
                                                :value="tres"
                                            ></v-radio>
                                            <v-radio
                                                label="Brillo"
                                                :value="cuatro"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-btn @click="dialogCalificacion=false" color="green" class="mr-4">
                                    Cerrar
                                </v-btn>
                                <submit-cx :block="false" class="mr-4" classBtn="mt-0"><v-icon left>mdi-content-save</v-icon> Guardar</submit-cx>
                            </form-cx>
                        <v-spacer></v-spacer>
                    </v-card>
                </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="headline">¿Está seguro que desea eliminar?</v-card-title>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">Si</v-btn>
                            <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogEstado" max-width="550px">
                        <v-card>
                            <v-card-title class="headline">¿Está seguro que desea finalizar publicación?</v-card-title>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeEstado">Cancelar</v-btn>
                            <v-btn color="blue darken-1" text @click="estadoItemConfirm">Si</v-btn>
                            <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>

                <template v-slot:[`item.foto`]="{ item }">
                    <v-img
                        class="p-2 m-2"
                        :src="item.foto ? item.foto : background" :lazy-src="background"
                        max-width="100" max-height="100" height="100">
                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                            ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                    
                </template>
                <template v-slot:[`item.nombre`]="{ item }">
                    <span class="text-xl">{{item.nombre}} | {{item.edad}} años</span>
                    <br/>
                    <span class="text-base"><span style="color:green">Salario pretendido:</span> {{item.salario ? `USD $${item.salario}` : 'No asignado'}}</span>
                    <br/>
                    <v-icon
                        small
                        :color="system.colores.icons_2"
                    >
                        mdi-map-marker 
                    </v-icon>
                    <span class="text-xs">{{ item.localidad }}, {{ item.provincia }}</span>
                </template>
                
                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu top :offset-y="offset">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" dark v-bind="attrs" v-on="on">
                            Acciones
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-list-item-title class="cursor-pointer">
                                    <v-btn color="primary" text @click="editItemCalificacion(item)">Calificar</v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title class="cursor-pointer"
                                >
                                <v-btn color="primary" text @click="descargarCV(item)">Descargar CV</v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title class="cursor-pointer" 
                                    title="Eliminar postulante" 
                                    >
                                    <v-btn color="primary" @click="deleteItem(item)" text>Eliminar</v-btn>
                                    </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    
                    
                    
                </template>
            </v-data-table>
            <div class="text-center pt-2">
                <v-pagination
                    v-model="page"
                    :length="numberOfPages"
                ></v-pagination>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import Api from '@/api';
import Cookies from 'js-cookie'
import TituloPrincipalCx from '@/components/titulos/TituloPrincipalCx'
import { mapGetters } from 'vuex'
import SubmitCx from '@/components/forms/SubmitCx'
import FormCx from '@/components/forms/FormCx'

export default {
    name: 'PostulantesPublicacion',
    components: { TituloPrincipalCx, FormCx,
        SubmitCx },
    data () {
        return {
            
            page: 1,
            totalAdministrarPublicaciones: 0,
            puesto: '',
            background: '',
            linkpdf: '',
            dialogLink: false,
            numberOfPages: 0,
            loading: true,
            uno: 1,
            dos: 2,
            tres: 3,
            cuatro: 4,
            search: '',
            nombreCalificar: '',
            options: {},
            AdministrarPublicaciones: [],
            dialogDelete: false,
            dialogEstado: false,
            dialogCalificacion: false,
            headers: [
                { text: ' ', value: 'foto', sortable: false },
                { text: ' ', value: 'nombre', sortable: false },
                { text: 'Acciones', value: 'actions', sortable: false },
            ],
            offset: true,
            items_actions: [{ text: 'Descargar CV'},{ text: 'Calificar'}],
            items_genero: [
                {value:null,text:'Cualquiera'},
                {value:'Femenino',text:'Femenino'},
                {value:'Masculino',text:'Masculino'},
                {value:'Otro',text:'Otro'}
            ],
            items_nivel_educativo: [
                {value: null, text:'Cualquiera'},
                {value:"Educación Inicial", text:'Educación Inicial'},
                {value:'Educación General Básica',text:'Educación General Básica'},
                {value:'Bachillerato',text:'Bachillerato'},
                {value:'Terciario',text:'Terciario'},
                {value:'Universitario',text:'Universitario'},
                {value:'Posgrado',text:'Posgrado'},
                {value:'Master',text:'Master'},
                {value:'Doctorado',text:'Doctorado'},
                {value:'Otro',text:'Otro'}
            ],
            items_presencia: [{value: null, text:'Cualquiera'},{value:1,text:"Mono"},{value:2,text:"Dragón"},{value:3,text:"Delfín"}],
            items_contactos: [{value: null, text:'Cualquiera'},{value:1,text:"Dorado"},{value:2,text:"Negro"},{value:3,text:"Check"}],
            items_verificado: [{value: null, text:'Cualquiera'},{value:1,text:"Trébol"},{value:2,text:"Pic"},{value:3,text:"Corazón"},{value:4,text:"Brillo"}],

            editedIndexCalificacion: -1,
            editedItemCalificacion: {
                _id: null,
                presencia: null,
                contactos: null,
                verificado: null
            },
            defaultItemCalificacion: {
                _id: null,
                presencia: null,
                contactos: null,
                verificado: null
            },

            genero: null,
            edad_desde: null,
            edad_hasta: null,
            salario_pretendido_hasta: null,
            experiencia_laboral_minima: null,
            nivel_educativo: null,
            valoracion_presencia: null,
            valoracion_contactos: null,
            valoracion_verificado: null
        }
    },
    computed: {
        ...mapGetters(['system','baseurl']),
        titulo() {
            return this.$route.params.id ? 'Postulantes para: '+this.puesto : 'Base de postulantes'
        },
        urlCalificacion() {
            return `/hojadevida/${this.editedItemCalificacion._id}`
        },
        methodCalificacion() {
            return 'put'
        },
        dataCalificacion() {
            return {
                presencia: this.editedItemCalificacion.presencia,
                contactos: this.editedItemCalificacion.contactos,
                verificado: this.editedItemCalificacion.verificado
            }
        }
    },
    watch: {
      options: {
        handler() {
            this.readDataFromAPI();
        },
      },  
      dialogDelete (val) {
        val || this.closeDelete()
      },
      dialogEstado (val) {
        val || this.closeEstado()
      },
      
      search: {
          handler() {
            this.fetchEntriesDebounced();
        },
      }
    },
    created() {
        this.readDataFromAPI()
        //this.$store.dispatch('setItemSelected','AdministrarPublicaciones_listar')
    },
    methods: {

        async success (response) {
            this.$notify(
                {
                    group: "bottom",
                    title: "Datos guardados",
                    text: response.data ? response.data.message : "Actualizado correctamente.",
                    type: 'success'
                },
                4000
            );
            this.dialogCalificacion = false;
            await this.readDataFromAPI()
        },
        error () {
            this.$notify(
                {
                    group: "bottom",
                    title: "Error",
                    text: "Ocurrió un inconveniente",
                    type: "error"
                },
                4000
            );
            
        },

        editItemCalificacion (item) {
            this.nombreCalificar = item.nombre;
            this.editedItemCalificacion = item.hoja_de_vida_info[0]
            this.dialogCalificacion = true
        },

        async descargarCV (item) {
            const HOJA_DE_VIDA_ID = item.hoja_de_vida_info ? item.hoja_de_vida_info[0]._id : null;
            const USUARIO_ID = item ? item.usuario_id : null;
            console.log(HOJA_DE_VIDA_ID);
            this.linkpdf= '';
            this.dialogLink = true;
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.baseurl,'get',headers,{})
                let response = await api.call_respuesta_directa(`/hojadevida/${HOJA_DE_VIDA_ID}/usuario/${USUARIO_ID}/pdf`) 
                this.dialogLink = true;
                this.linkpdf = response.data.data
            }
            
        },


        fetchEntriesDebounced() {
            // cancel pending call
            clearTimeout(this._timerId)
            this.page = 1
            // delay new call 500ms
            this._timerId = setTimeout(() => {
                this.readDataFromAPI()
            }, 500)
        },

        async readDataFromAPI() {
            const OFERTA_LABORAL_ID = this.$route.params.id;
            this.loading = true;
            const { page, itemsPerPage } = this.options;
            if (page) {
                let pageNumber = page - 1;
                let params = {
                    size: itemsPerPage,
                    page: pageNumber,
                    
                    //oferta_laboral_id: OFERTA_LABORAL_ID,
                    filtros: {
                        genero: this.genero, 
                        edad_desde: parseInt(this.edad_desde), 
                        edad_hasta: parseInt(this.edad_hasta), 
                        salario_pretendido_hasta: parseFloat(this.salario_pretendido_hasta), 
                        experiencia_laboral_minima: parseInt(this.experiencia_laboral_minima),
                        nivel_educativo: this.nivel_educativo, 
                        valoracion_presencia: this.valoracion_presencia, 
                        valoracion_contactos: this.valoracion_contactos, 
                        valoracion_verificado: this.valoracion_verificado,
                        clave: this.search
                    }
                };
                if(OFERTA_LABORAL_ID){
                    params.oferta_laboral_id = OFERTA_LABORAL_ID
                }
                let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null
                let AdministrarPublicaciones = []
                if (userLogged) {
                    let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                    const api = new Api(this.baseurl,'get',headers,{},params)
                    let response = await api.call_respuesta_directa(`/postulacion-trabajo/postulantes`) 
                    AdministrarPublicaciones = response.data ? response.data.data : [];
                    this.loading = false;
                    this.totalAdministrarPublicaciones = response.data.totalAdministrarPublicaciones;
                    this.numberOfPages = response.data.totalPages;
                    this.puesto = response.data.oferta
                    this.background = response.data.background
                }
                this.AdministrarPublicaciones = AdministrarPublicaciones
            }
            
        },

        estadoItem (item) {
            this.editedIndex = this.AdministrarPublicaciones.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogEstado = true
        },

        estadoItemConfirm () {
            this.estadoApi()
        },

        async estadoApi() {
            let response = false;
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null;
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.$store.getters.baseurl,'put',headers,{estado: "Finalizado"})
                response = await api.call_respuesta_directa(`/postulacion-trabajo/${this.editedItem._id}`) 
            }
            if (response && response.status == 200) {
                //this.AdministrarPublicaciones.splice(this.editedIndex, 1)
                this.closeEstado()
                this.readDataFromAPI()
            }else{
                this.$notify(
                    {
                        group: "bottom",
                        title: "Error",
                        text: "No se pudo finalizar publicación",
                        type: "error"
                    },
                    4000
                );
                this.closeEstado()
            }

        },

        deleteItem (item) {
            this.editedIndex = this.AdministrarPublicaciones.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm () {
            this.deleteApi()
        },

        async deleteApi() {
            let response = false;
            let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null;
            if (userLogged) {
                let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
                const api = new Api(this.$store.getters.baseurl,'delete',headers)
                response = await api.call_respuesta_directa(`/postulacion-trabajo/postulantes/${this.editedItem._id}`) 
            }
            if (response && response.status == 200) {
                this.AdministrarPublicaciones.splice(this.editedIndex, 1)
                this.closeDelete()
                this.readDataFromAPI()
            }else{
                this.$notify(
                    {
                        group: "bottom",
                        title: "Error",
                        text: "No se pudo eliminar empresa",
                        type: "error"
                    },
                    4000
                );
                this.closeDelete()
            }

        },


        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        closeEstado () {
            this.dialogEstado = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        }
    }
}
</script>

<style scoped>
    tbody tr:nth-child(odd) {
        background-color:rgba(79, 125, 147, 10%);
    }
</style>