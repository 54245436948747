<template>
   <div> 
    <home />
    
    <download />
    
    <contact />
   </div>
</template>

<script>


import home from "./components/HomeSection";
//import about from "./components/AboutSection";
import download from "./components/DownloadSection";
//import ofertas from "./components/OfertasLaborales";
//import pricing from "./components/PricingSection";
import contact from "./components/ContactSection";

export default {
    name: 'ExternaEcuador',
    
    data () {
        return {
        dialog: false,
        nav: [
            {
            icon: 'home',
            text: 'Home',
            title: 'Back to Home page',
            active: true
            },
            {
            icon: 'info',
            text: 'About',
            title: 'About this demo',
            active: false
            },
            {
            icon: 'assignment_turned_in',
            text: 'Todos',
            title: 'Some stuff that needs doing',
            active: false
            },
            {
            icon: 'email',
            text: 'Contact',
            title: 'Our Contact info',
            active: false
            }
        ]
        }
    },
    
    created() {
        //this.readDataFromAPI()
    },
    methods: {
 
    },
    components: {
        
        home,
        //about,
        download,
        //pricing,
        contact,
        //ofertas
    }
}
</script>

<style scoped>
    
</style>