<template>
    <div class="w-full" >
        No Autorizado
    </div>
</template>
<script>
export default {
    name:'UnauthorizedCx'
}
</script>
<style scoped>

</style>