import Home from '@/components/Home'
import HomeUser from '@/components/HomeUser'
import HomeSign from '@/components/HomeSign'

import ExternaEcuadorHome from '@/pages/ExternaEcuadorHome'
import ExternaEcuador from '@/pages/ExternaEcuador'

import Tests from '@/pages/evaluaciones/Tests'
import TestsListar from '@/pages/evaluaciones/TestsListar'

import EvaluacionDetalle from '@/pages/users/evaluaciones/EvaluacionDetalle'
import EvaluacionDetalleDesempeno from '@/pages/users/evaluaciones/EvaluacionDetalleDesempeno'
import EvaluacionesLista from '@/pages/users/evaluaciones/EvaluacionesLista'
import ReporteRevisionUsuario from '@/pages/users/evaluaciones/ReporteRevisionUsuario'

/*import PorCalificarDetalle from '@/pages/users/evaluaciones/PorCalificarDetalle'
import PorCalificarLista from '@/pages/users/evaluaciones/PorCalificarLista'*/

import Empresas from '@/pages/empresas/Empresas'
import EmpresasListar from '@/pages/empresas/EmpresasListar'

import Subdimensiones from '@/pages/subdimensiones/Subdimensiones'
import SubdimensionesListar from '@/pages/subdimensiones/SubdimensionesListar'

import Departamentos from '@/pages/departamentos/Departamentos'
import DepartamentosListar from '@/pages/departamentos/DepartamentosListar'

import Direcciones from '@/pages/direcciones/Direcciones'
import DireccionesListar from '@/pages/direcciones/DireccionesListar'

import Cargos from '@/pages/cargos/Cargos'
import CargosListar from '@/pages/cargos/CargosListar'

import Admins from '@/pages/admins/Admins'
import AdminsListar from '@/pages/admins/AdminsListar'

import ReporteClima from '@/pages/reportes/ReporteClima'
import ReporteClimaComparativo from '@/pages/reportes/ReporteClimaComparativo'
import ReportePostCovid from '@/pages/reportes/ReportePostCovid'
import ReporteDiagnostico from '@/pages/reportes/ReporteDiagnostico'
import ReporteDesempenoEspecifico from '@/pages/reportes/ReporteDesempenoEspecifico'
import ReporteDesempenoComparativo from '@/pages/reportes/ReporteDesempenoComparativo'
import ReporteDesempenoGeneral from '@/pages/reportes/ReporteDesempenoGeneral'

import ReportesPendientes from '@/pages/reportes/ReportesPendientes'
import HojaDeVida from '@/pages/hoja_de_vida/HojaDeVida'
import PostularTrabajo from '@/pages/postulaciones/PostularTrabajo'
import AdministrarPublicaciones from '@/pages/postulaciones/AdministrarPublicaciones'
import PostulantesPublicacion from '@/pages/postulaciones/PostulantesPublicacion'
import OfertaLaboralUsuario from '@/pages/postulaciones/OfertaLaboralUsuario'
import OfertasLaborales from '@/pages/postulaciones/OfertasLaborales'
import OfertaLaboralEspecificaUsuario from '@/pages/postulaciones/OfertaEspecificaUsuario'
import PostulacionRealizada from '@/pages/postulaciones/PostulacionRealizada'
import MisPostulaciones from '@/pages/postulaciones/MisPostulaciones'

import LoginSuperAdminCx from '@/pages/auth/LoginSuperAdminCx'
import LoginUserCx from '@/pages/auth/LoginUserCx'
import OlvideContrasena from '@/pages/auth/OlvideContrasena'
import ConfirmUsuario from '@/pages/auth/ConfirmUsuario'
import RestableceContrasena from '@/pages/auth/RestableceContrasena'
import RegistroUser from '@/pages/auth/RegistroUser'
import UnauthorizedCx from '@/pages/UnauthorizedCx'

export default [
    { path: '/',meta: { requiresAuth: false }, component: ExternaEcuadorHome, children: [
      { path: '/', name: 'HomeExternaEcuador', meta: { requiresAuth: false }, component: ExternaEcuador },
      { path: 'ofertas-laborales', meta: { requiresAuth: false, rol: ['user'], listName:'hojadevida'}, name: 'OfertaLaboralUsuario', component: OfertaLaboralUsuario },
      { path: 'ofertas-laborales/:id', meta: { requiresAuth: false, rol: ['user'], listName:'hojadevida'}, name: 'OfertaLaboralEspecificaUsuario', component: OfertaLaboralEspecificaUsuario },
      { path: 'ofertas-laborales/:id/postular', meta: { requiresAuth: true, rol: ['user'], listName:'hojadevida'}, name: 'PostulacionRealizada', component: PostulacionRealizada },
    ] },
    { path: '/ev', component: HomeUser, children: [
        { path: '/',meta: { requiresAuth: true, rol: ['user']}, redirect: { name: 'EvaluacionesLista' } },
        { path: 'evaluaciones-lista', meta: { requiresAuth: true, rol: ['user'], listName:'evaluaciones'}, name: 'EvaluacionesLista', component: EvaluacionesLista },
        { path: 'evaluacion/:id', meta: { requiresAuth: true, rol: ['user'], listName:'evaluaciones'}, name: 'EvaluacionesDetalle', component: EvaluacionDetalle },
        { path: 'evaluacion/:id/:_id_usuario', meta: { requiresAuth: true, rol: ['user'], listName:'evaluaciones'}, name: 'EvaluacionesDetalleDesempeno', component: EvaluacionDetalleDesempeno },
        { path: 'reporte/:id/revision', meta: { requiresAuth: true, rol: ['user'], listName:'evaluaciones'}, name: 'ReporteRevisionUsuario', component: ReporteRevisionUsuario },
        /*{ path: 'evaluaciones-por-calificar', meta: { requiresAuth: true, rol: 'user', listName:'evaluaciones'}, name: 'PorCalificarLista', component: PorCalificarLista },
        { path: 'evaluacion-por-calificar/:id/user/:usuario', meta: { requiresAuth: true, rol: 'user', listName:'evaluaciones'}, name: 'PorCalificarDetalle', component: PorCalificarDetalle }*/
      ]
    },
    { path: '/hv', component: HomeUser, children: [
      { path: 'hojadevida', meta: { requiresAuth: true, rol: ['user'], listName:'hojadevida'}, name: 'HojaDeVida', component: HojaDeVida },
      { path: 'postular', meta: { requiresAuth: true, rol: ['user'], listName:'hojadevida'}, name: 'PostularCV', component: OfertasLaborales },
      { path: 'mispostulaciones', meta: { requiresAuth: true, rol: ['user'], listName:'hojadevida'}, name: 'MisPostulaciones', component: MisPostulaciones },
      ]
    },
    { path: '/backoffice', component: Home, children: [
        { path: '/',meta: { requiresAuth: true, rol: ['superadmin','admin'] }, redirect: { name: 'TestListar' } },
        { path: 'tests-listar', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'evaluaciones' }, name: 'TestListar', component: TestsListar },
        { path: 'tests', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'evaluaciones' }, name: 'Tests', component: Tests },
        { path: 'tests/:id/:step', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'evaluaciones'}, name: 'TestDetalle', component: Tests },
        { path: 'empresas', meta: { requiresAuth: true, rol: ['superadmin'], listName:'empresas'}, name: 'Empresas', component: Empresas },
        { path: 'empresas/:id', meta: { requiresAuth: true, rol: ['superadmin'], listName:'empresas'}, name: 'EmpresaDetalle', component: Empresas },
        { path: 'empresas-listar', meta: { requiresAuth: true, rol: ['superadmin'], listName:'empresas'}, name: 'EmpresasListar', component: EmpresasListar },
        { path: 'subdimensiones', meta: { requiresAuth: true, rol: ['superadmin'], listName:'subdimensiones'}, name: 'Subdimensiones', component: Subdimensiones },
        { path: 'subdimensiones/:id', meta: { requiresAuth: true, rol: ['superadmin'], listName:'subdimensiones'}, name: 'SubdimensionDetalle', component: Subdimensiones },
        { path: 'subdimensiones-listar', meta: { requiresAuth: true, rol: ['superadmin'], listName:'subdimensiones'}, name: 'SubdimensionesListar', component: SubdimensionesListar },

        { path: 'departamentos', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'departamentos'}, name: 'Departamentos', component: Departamentos },
        { path: 'departamentos/:id', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'departamentos'}, name: 'DepartamentoDetalle', component: Departamentos },
        { path: 'departamentos-listar', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'departamentos'}, name: 'DepartamentosListar', component: DepartamentosListar },

        { path: 'direcciones', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'direcciones'}, name: 'Direcciones', component: Direcciones },
        { path: 'direcciones/:id', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'direcciones'}, name: 'DireccionDetalle', component: Direcciones },
        { path: 'direcciones-listar', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'direcciones'}, name: 'DireccionesListar', component: DireccionesListar },

        { path: 'cargos', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'cargos'}, name: 'Cargos', component: Cargos },
        { path: 'cargos/:id', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'cargos'}, name: 'CargoDetalle', component: Cargos },
        { path: 'cargos-listar', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'cargos'}, name: 'CargosListar', component: CargosListar },

        { path: 'admins', meta: { requiresAuth: true, rol: ['superadmin'], listName:'admins'}, name: 'Admins', component: Admins },
        { path: 'admins/:id', meta: { requiresAuth: true, rol: ['superadmin'], listName:'admins'}, name: 'AdminDetalle', component: Admins },
        { path: 'admins-listar', meta: { requiresAuth: true, rol: ['superadmin'], listName:'admins'}, name: 'AdminsListar', component: AdminsListar },

        { path: 'reportes/pendientes', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'reportes' }, name: 'ReportesPendientes', component: ReportesPendientes },

        { path: 'reportes/clima', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'reportes' }, name: 'ReporteClima', component: ReporteClima, props: true },
        { path: 'reportes/clima/comparativo', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'reportes' }, name: 'ReporteClimaComparativo', component: ReporteClimaComparativo, props: true },
        { path: 'reportes/postcovid', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'reportes' }, name: 'ReportePostCovid', component: ReportePostCovid, props: true },
        { path: 'reportes/diagnostico', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'reportes' }, name: 'ReporteDiagnostico', component: ReporteDiagnostico, props: true },
        { path: 'reportes/desempeno/especifico', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'reportes' }, name: 'ReporteDesempenoEspecifico', component: ReporteDesempenoEspecifico, props: true },
        { path: 'reportes/desempeno/comparativo', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'reportes' }, name: 'ReporteDesempenoEspecificoComparativo', component: ReporteDesempenoComparativo, props: true },
        { path: 'reportes/desempeno/general', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'reportes' }, name: 'ReporteDesempenoGeneral', component: ReporteDesempenoGeneral, props: true },

        { path: 'postular-trabajo', meta: { requiresAuth: true, rol: ['superadmin','admin'], listName:'postulaciones' }, name: 'PostularTrabajo', component: PostularTrabajo, props: true },
        { path: 'administrar-publicaciones', meta: { requiresAuth: true, rol: ['superadmin'], listName:'postulaciones' }, name: 'AdministrarPublicaciones', component: AdministrarPublicaciones, props: true },
        { path: 'administrar-publicaciones/:id', meta: { requiresAuth: true, rol: ['superadmin'], listName:'postulaciones'}, name: 'PostulantesPublicacion', component: PostulantesPublicacion },
        { path: 'base-postulantes', meta: { requiresAuth: true, rol: ['superadmin'], listName:'postulaciones'}, name: 'BasePostulantes', component: PostulantesPublicacion },
      ]
    },
    { path: '/backoffice/auth', meta: { requiresAuth: false }, component: HomeSign, children: [
        { path: '/', name: 'LoginSuperAdmin', meta: { requiresAuth: false }, component: LoginSuperAdminCx, props:{rol:'superadmin'} },
      ] 
    },
    { path: '/admin', meta: { requiresAuth: false }, component: HomeSign, children: [
        { path: '/', name: 'LoginAdminHome', meta: { requiresAuth: false }, redirect: { name: 'LoginAdmin' } },
        { path: '/admin/auth', name: 'LoginAdmin', meta: { requiresAuth: false }, component: LoginSuperAdminCx, props:{rol:'admin'} },
      ] 
    },
    { path: '/auth', meta: { requiresAuth: false }, component: HomeSign, children: [
        { path: '/', name: 'LoginUser', meta: { requiresAuth: false }, component: LoginUserCx },
        { path: 'olvide-contrasena', name: 'OlvideContrasena', meta: { requiresAuth: false }, component: OlvideContrasena },
        { path: 'restablece/contrasena/:token', name: 'RestableceContrasena', meta: { requiresAuth: false }, component: RestableceContrasena }
      ]
    },
    { path: '/registro-user', meta: { requiresAuth: false }, component: HomeSign, children: [
        { path: '/', name: 'RegistroUser', meta: { requiresAuth: false }, component: RegistroUser }
      ]
    },
    { path: '/auth/unauthorized', name: 'Unauthorized', meta: { requiresAuth: false }, component: UnauthorizedCx },
    { path: '/auth/confirm/:token', name: 'ConfirmUsuario', meta: { requiresAuth: false }, component: ConfirmUsuario }
    
    
    /*,
    { path: '/clientes', component: HomeClientes, children: [
      { path: '/',meta: { requiresAuth: true, admin: false }, redirect: { name: 'ClientesEncuesta' } },
      { path: '/clientes/encuesta', meta: { requiresAuth: true, admin: false }, name: 'ClientesEncuesta', component: ClientesEncuesta }
    ]*/
]