<template>
  <section id="download">
    <v-container fluid class="numbers">
      <v-row>
        <v-col cols="12">
          <v-row align="center" justify="space-around">
            <v-col cols="12" sm="3">
                <div class="number-item">
                    <countTo class="font-weight-bold display-1" :startVal='0' :endVal='5' :duration='5000'></countTo>
                    <h4 class="font-weight-bold">Años de experiencia en consultoría integral RRHH <!--de Recursos Humanos--></h4>
                </div>
            </v-col>

            <v-col cols="12" sm="3">
                <div class="number-item">
                    <countTo class="font-weight-bold display-1" :startVal='0' :endVal='247' :duration='5000'></countTo>
                    <h4 class="font-weight-bold">Empresas asesoradas por nosotros</h4>
                </div>
            </v-col>

            <v-col cols="12" sm="3">
                <div class="number-item">
                    <countTo class="font-weight-bold display-1" :startVal='0' :endVal='7836' :duration='5000'></countTo>
                    <h4 class="font-weight-bold">Clientes atendidos</h4>
                </div>
            </v-col>

            <v-col cols="12" sm="3">
                <div class="number-item">
                    <countTo class="font-weight-bold display-1" :startVal='0' :endVal='382' :duration='5000'></countTo>
                    <h4 class="font-weight-bold">Personas tienen empleo a través de nosotros</h4>
                </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>

import countTo from 'vue-count-to';

export default {
  data () {
      return {
        
      }
    },
  components: { countTo },
};
</script>

<style scoped>
#download {
  background-image: url("~@/assets/img/bgDownload.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
}

.numbers {
   
    min-height: 400px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.number-item {
    border: #0000F0 4px solid;
    border-radius: 8px;
    color: #fff;
    padding: 40px 0px;
    text-align: center;
    min-height: 280px !important;
}

.number-item h3 {
    
    font-size: 50px;
}

.number-item h4 {
    
    font-size: 24px;
    margin-top: 20px;
}

#download .container,
#download .row {
  height: 100%;
}
</style>

