<template>
    <v-row class="py-4">
        <!--<v-col cols="12" class="py-0">
            <titulo-principal-cx titulo="Login" />
        </v-col>-->
        <v-col cols="12" class="py-16">

            <v-dialog v-model="dialogEnviado" max-width="550px">
                <v-card>
                    <v-card-title class="headline">{{ message }}</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    
                    <v-btn color="success" @click="aceptarDialog()">
                        Aceptar
                    </v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <form-cx action="#" :data="data" :auth='auth' url="/auth/recuperar" @success="success" @error="error">
                <v-container>
                    <v-row>
                        <v-col cols="12"  class="md:border-b md:p-6">
                            <v-img
                            lazy-src="https://picsum.photos/id/11/10/6"
                            max-height="77"
                            max-width="435"
                            :src="system.logo_max"
                            class="m-auto w-full"
                            ></v-img>
                        </v-col>
                        <v-col cols="12" offset-md="4"  md="4" class="md:p-6">

                            <h2 class="text-lg">Recuperar contraseña</h2>
                            <h3 class="text-sm">Ingresa tu email</h3>
                            <br>
                            <v-text-field
                                v-model="data.email"
                                :rules="rules.email.rule"
                                type="email"
                                label="Email"
                                required
                            ></v-text-field>

                            
                            <br>
                            <submit-cx class="mb-2">Recuperar</submit-cx>
                            <br><br>
                            
                        </v-col>
                    </v-row>
                </v-container>
                
            </form-cx>
        </v-col>
    </v-row>
</template>

<script>

//import TituloPrincipalCx from '@/components/titulos/TituloPrincipalCx'
import FormCx from '@/components/forms/FormCx'
import SubmitCx from '@/components/forms/SubmitCx'
import { mapGetters } from 'vuex'

export default {
    name: 'OlvideContrasena',
    data () {
        return {
            data: {
                email: ''
            },
            dialogEnviado: false,
            message: '',
            rules: {
                email: {
                    rule: [
                        v => !!v || 'Email es requerido',
                        v => {
                            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            return pattern.test(v) || 'Email inválido.'
                        }
                    ]
                }
            },
            show1: false,
        }
    },
    computed: {
        ...mapGetters(['system','apiKeyUser']),
        auth() {
            return {
                username: this.data.email,
                password: this.data.password
            }
        }
    },
    components: {
        //TituloPrincipalCx,
        FormCx,
        SubmitCx
    },
    watch: {
        'data.email'(val) {
            this.data.username = val.trim()
        }
    },
    methods: {
        success (response) {
            this.message = response.data.message;
            this.dialogEnviado = true;
            
        },

        aceptarDialog () {
            this.dialogEnviado = false;
            this.$router.push({path:"/auth"});
        },
        error (response) {
            this.$notify(
                {
                    group: "bottom",
                    title: "Error",
                    text: response.data.error ? response.data.error : "Datos incorrectos.",
                    type: "error"
                },
                6000
            );
            
        }
    }
}
</script>

<style scoped>

</style>