<template>
  <v-footer dark padless>
    <v-card flat tile class="secondary white--text text-center">
      <v-card-text>
        <v-btn
          v-for="(icon, i) in icons"
          :key="i"
          class="mx-4 white--text"
          :href="icon.link"
          target="_blank"
          icon
        >
          <v-icon size="24px">{{ icon.text }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        SU RECURSO MÁS VALIOSO EN LAS MEJORES MANOS.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Externa Talent Hunters</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
export default {
  data: () => ({
    icons: [
      {
        text: "mdi-facebook",
        link: "https://www.facebook.com/pg/externaecuador/about/?ref=page_internal",
      },
      {
        text: "mdi-twitter",
        link: "https://twitter.com/EXTERNAECUADOR?s=08",
      },
      {
        text: "mdi-linkedin",
        link: "https://www.linkedin.com/company/40697529/admin/",
      },
      {
        text: "mdi-instagram",
        link: "https://instagram.com/externath?igshid=1b5oa6ygq4a8r",
      },
    ],
  }),
};
</script>
