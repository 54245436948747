<template>
    <v-row class="py-2">
        <v-col cols="12" class="py-2">
            <form-cx action="#" :data="data"  url="/auth/registro" @success="success" @error="error">
                <v-container>
                    <v-row>
                        <v-col cols="12"  class="md:border-b md:p-6">
                            <v-img
                            lazy-src="https://picsum.photos/id/11/10/6"
                            max-height="38"
                            max-width="217"
                            :src="system.logo_max"
                            class="m-auto w-full"
                            ></v-img>
                        </v-col>
                        <v-col cols="12" offset-md="4"  md="4" class="md:p-6">

                            <h2 class="text-lg">Regístrate</h2>
                            <h3 class="text-sm">Ingresa los siguientes datos:</h3>
                            <br>
                            <v-text-field
                                v-model="data.nombre"
                                :rules="rules.nombre.rule"
                                type="text"
                                label="Nombre"
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="data.apellido"
                                :rules="rules.apellido.rule"
                                type="text"
                                label="Apellido"
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="data.email"
                                :rules="rules.email.rule"
                                type="email"
                                label="Email"
                                required
                            ></v-text-field>

                            <v-text-field
                                v-model="data.password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="rules.password.rule"
                                :type="show1 ? 'text' : 'password'"
                                label="Contraseña"
                                @click:append="show1 = !show1"
                            ></v-text-field>

                            <v-text-field
                                v-model="data.repitepassword"
                                :append-icon="show1Repite ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="rules.repitepassword.rule"
                                :type="show1Repite ? 'text' : 'password'"
                                label="Repite contraseña"
                                @click:append="show1Repite = !show1Repite"
                            ></v-text-field>
                            <br>
                            <submit-cx class="mb-2">Registrarse</submit-cx>
                            <br>
                        </v-col>
                    </v-row>
                </v-container>
                
            </form-cx>
        </v-col>
    </v-row>
</template>

<script>

//import TituloPrincipalCx from '@/components/titulos/TituloPrincipalCx'
import FormCx from '@/components/forms/FormCx'
import SubmitCx from '@/components/forms/SubmitCx'
import { mapGetters } from 'vuex'

export default {
    name: 'RegistroUser',
    data () {
        return {
            data: {
                nombre: '',
                apellido: '',
                email: '',
                password: '',
                repitepassword: ''
            },
            rules: {
                nombre: {
                    rule: [
                        v => !!v || 'Nombre es requerido',
                        v => v.length <= this.rules.nombre.counter || `El nombre debe ser menor o igual a ${this.rules.nombre.counter} caracteres`,
                    ],
                    counter: 50
                },
                apellido: {
                    rule: [
                        v => !!v || 'Apellido es requerido',
                        v => v.length <= this.rules.apellido.counter || `El apellido debe ser menor o igual a ${this.rules.apellido.counter} caracteres`,
                    ],
                    counter: 50
                },
                email: {
                    rule: [
                        v => !!v || 'Email es requerido',
                        v => {
                            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            return pattern.test(v) || 'Email inválido.'
                        }
                    ]
                },
                password: {
                    rule: [
                        v => !!v || 'Password es requerido',
                    ],
                    counter: 13
                },
                repitepassword: {
                    rule: [
                        v => {
                            if (v !== this.data.password) 
                                return "No coinciden passwords"
                            else
                                return true
                        }
                    ]
                }
            },
            show1: false,
            show1Repite: false,
        }
    },
    computed: {
        ...mapGetters(['system','apiKeyUser']),
        
    },
    components: {
        //TituloPrincipalCx,
        FormCx,
        SubmitCx
    },
    watch: {
        'data.email'(val) {
            this.data.email = val.trim()
        },
        'data.password'(val) {
            this.data.password = val.trim()
        },
        'data.repitepassword'(val) {
            this.data.repitepassword = val.trim()
        }
    },
    methods: {
        success () {
            //Cookies.set("userLogged", response.data)
            this.$notify(
                {
                    group: "bottom",
                    title: "Creado usuario",
                    text: "Usuario creado correctamente. Se ha enviado un email para su activación.",
                    type: "success"
                },
                8000
            );
            this.$router.push("/auth");
        },
        error (response) {
            console.dir(response)
            this.$notify(
                {
                    group: "bottom",
                    title: "Error",
                    text: response.data.message ? response.data.message : "Datos incorrectos.",
                    type: "error"
                },
                4000
            );
            
        }
    }
}
</script>

<style scoped>

</style>