<template>
    <v-row style="margin-top: 84px;background-color:white;">
        
        <v-dialog v-model="dialogPostulado" max-width="550px" persistent>
            <v-card>
                <v-card-title class="headline">POSTULADO CORRECTAMENTE</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                
                <v-btn color="success" @click="aceptarPostulado()">
                    Aceptar
                </v-btn>
                
                
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import Api from '@/api';
import Cookies from 'js-cookie'

export default {
    name: 'PostulacionRealizada',
    components: { },
    data () {
        return {
            dialogPostulado: false
        }
    },
    computed: {
        
    },
    watch: {

        
      
    },
    async created() {
        const ID_OFERTA = this.$route.params.id;
        let response = false;
        let userLogged = Cookies.get("userLogged") ? JSON.parse(Cookies.get("userLogged")) : null;
        if (userLogged && ID_OFERTA) {
            let headers = { 'Content-Type': 'application/json','Authorization': `Bearer ${userLogged.token}` }
            const api = new Api(this.$store.getters.baseurl,'post',headers,{oferta_laboral_id: ID_OFERTA})
            response = await api.call_respuesta_directa(`/postulacion-trabajo/postular`) 
            if (response && response.status == 201) {
                //this.AdministrarPublicaciones.splice(this.editedIndex, 1)
                this.dialogPostulado = true;
            }else{
                this.$notify(
                    {
                        group: "bottom",
                        title: "Error",
                        text: response.response ? response.response.data.message : "No se pudo postular",
                        type: "error"
                    },
                    5000
                );
                this.$router.push({path:"/ofertas-laborales"})
            }
        }
        

    },
    methods: {
        
        aceptarPostulado() {
            this.dialogPostulado=false;
            this.$router.push({path:"/ofertas-laborales"})
        }
        
    }
}
</script>

<style scoped>
    tbody tr:nth-child(odd) {
        background-color:rgba(79, 125, 147, 10%);
    }
</style>