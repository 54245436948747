<template>
    <v-row>
        <v-dialog v-model="loading" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Confirmando, por favor espere ...
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-col cols="12" class="py-2">
            <form-cx action="#" v-if="dialogProcesado" :data="data" method="put" :url="url" @success="success" @error="error">
                <v-container>
                    <v-row>
                        <v-col cols="12"  class="md:border-b md:p-6">
                            <v-img
                            lazy-src="https://picsum.photos/id/11/10/6"
                            max-height="77"
                            max-width="435"
                            :src="system.logo_max"
                            class="m-auto w-full"
                            ></v-img>
                        </v-col>
                        <v-col cols="12" offset-md="4"  md="4" class="md:p-6">

                            <h2 class="text-lg">Ingresa una nueva contraseña</h2>
                            <h3 class="text-sm">Recuerda ingresar una contraseña segura</h3>
                            <br>
                            <v-text-field
                                v-model="data.password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="rules.password.rule"
                                :type="show1 ? 'text' : 'password'"
                                label="Nueva contraseña"
                                @click:append="show1 = !show1"
                            ></v-text-field>

                            <v-text-field
                                v-model="data.repitepassword"
                                :append-icon="show1Repite ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="rules.repitepassword.rule"
                                :type="show1Repite ? 'text' : 'password'"
                                label="Repite contraseña"
                                @click:append="show1Repite = !show1Repite"
                            ></v-text-field>
                            
                            <br>
                            <submit-cx class="mb-2">Guardar</submit-cx>
                            <br><br>
                            
                        </v-col>
                    </v-row>
                </v-container>
                
            </form-cx>
        </v-col>
    </v-row>
</template>

<script>
import Api from '@/api';
import { mapGetters } from 'vuex'
import SubmitCx from '@/components/forms/SubmitCx'
import FormCx from '@/components/forms/FormCx'

export default {
    name: 'RestableceContrasena',
    components: { SubmitCx, FormCx },
    data () {
        return {
            dialogProcesado: false,
            loading: true,
            user_id: null,
            data: {
                password: '',
                repitepassword: ''
            },
            show1: false,
            show1Repite: false,
            rules: {
                password: {
                    rule: [
                        v => !!v || 'Password es requerido',
                    ],
                    counter: 13
                },
                repitepassword: {
                    rule: [
                        v => {
                            if (v !== this.data.password) 
                                return "No coinciden passwords"
                            else
                                return true
                        }
                    ]
                }
            }
        }
    },
    computed: {
        ...mapGetters(['system','baseurl']),
        url() {
            return `/auth/usuario/password/${this.user_id}`
        }
    },
    watch: {
        'data.password'(val) {
            this.data.password = val.trim()
        },
        'data.repitepassword'(val) {
            this.data.repitepassword = val.trim()
        }
    },
    created() {
        this.readToken()
    },
    methods: {

        async readToken() {
            this.loading = true;
            const TOKEN = this.$route.params.token;
            let params = {
                token: TOKEN
            }
            let headers = { 'Content-Type': 'application/json' }
            const api = new Api(this.baseurl,'get',headers,{},params)
            let response = await api.call_respuesta_directa('/auth/restablece/contrasena') 
            if (response.status === 200) {
                this.dialogProcesado = true;
                this.user_id = response.data.data;
            } else {
                this.$notify(
                    {
                        group: "bottom",
                        title: "Error",
                        text: response.response.data.message ? response.response.data.message : "Datos incorrectos.",
                        type: "error"
                    },
                    6000
                );
                this.$router.push({path: "/auth"})
            }
            this.loading = false;

            
        },

        success () {
            //Cookies.set("userLogged", response.data)
            this.$notify(
                {
                    group: "bottom",
                    title: "Contraseña modificada",
                    text: "Se ha modificado correctamente su contraseña.",
                    type: "success"
                },
                8000
            );
            this.$router.push("/");
        },
        error (response) {
            this.$notify(
                {
                    group: "bottom",
                    title: "Error",
                    text: response.data.message ? response.data.message : "Datos incorrectos.",
                    type: "error"
                },
                4000
            );
            
        }

        
    }
}
</script>

<style scoped>
    tbody tr:nth-child(odd) {
        background-color:rgba(79, 125, 147, 10%);
    }
</style>